import React from "react";
import { BooleanInput, Filter, FilterProps, TextField, TextInput, SelectInput, ReferenceInput } from "ra-ui-materialui";
import { AutocompleteInput, SearchInput } from "react-admin";




export default (props: any) => (
    <Filter {...props}>
        <ReferenceInput label="User" source="sender|to" reference="user" allowEmpty alwaysOn filterToQuery={txt => ({ profile: { nickname: txt } })}>
            <AutocompleteInput optionText="profile.nickname"/>
        </ReferenceInput>
        
    </Filter>
)