import React from 'react';
import { BooleanInput } from 'ra-ui-materialui';
import { useQuery, DeleteButton } from 'react-admin';
import { Grid } from '@material-ui/core';

const UserDeleteWithControl = (props: any) => {

    const { source, helperText, record } = props;
    const { email, deleted } = record;

    const { data, loading, error } = useQuery({
        type: 'getList',
        resource: 'user',
        payload: {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'email', order: 'DESC' },
            filter: { email, withDeleted: 1 }
        },
    }, { enabled: deleted });

    const isUnDeletedAlow = data?.every((el: any) => {
        return el.deleted === true;
    });

    return (
        <>
            <Grid container item xs={12} sm={12} xl={12}>
                <Grid item xs={6} sm={4} xl={4}>
                    <BooleanInput source={source}
                        helperText={helperText}
                        record={record}
                        disabled={!isUnDeletedAlow}
                    />
                </Grid>
                <Grid item xs={6} sm={4} xl={4}>
                    <DeleteButton record={record} disabled={deleted} />
                </Grid>
            </Grid>
        </>
    );
};

export default UserDeleteWithControl;