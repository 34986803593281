import { Datagrid, DateField, List, ListProps, TextField, ReferenceField, Button, ShowButton, ButtonProps, PaginationProps, Pagination } from "react-admin";
import { DateTimeField } from "../User/UserList/UserFields";
import MessageField from "./MessageField";
import MessageFilter from "./MessageFilter";

export default (props: ListProps) => (
    <List {...props}
        syncWithLocation
        sort={{ field: 'createdAt', order: 'DESC' }}
        filters={<MessageFilter />}
        perPage={100}
        pagination={<MessagesPagination />}
    >
        <Datagrid hasBulkActions={false}>
            <ReferenceField label="From" source="sender" reference="user">
                <TextField source="profile.nickname" />
            </ReferenceField>

            <ReferenceField label="To" source="to" reference="user">
                <TextField source="profile.nickname" />
            </ReferenceField>
            <MessageField source="content" type="type" />

            {/* <TextField source="id" /> */}
            {/* <TextField source="received" /> */}
            {/* <TextField source="type" /> */}
            {/* <TextField source="conversation" /> */}
            <ShowConversationButton />
            {/* <TextField source="text" /> */}
            {/* <TextField source="content" /> */}
            <DateTimeField source="sent" />
            {/* <DateField source="createdAt" /> */}
            {/* <DateField source="updatedAt" /> */}

            {/* <TextField source="sender" />
            <TextField source="to" /> */}
        </Datagrid>
    </List>
);

const ShowConversationButton = ({ record }: ButtonProps) => (
    <ShowButton basePath="/conversation" record={{ id: record!.conversation }} />
);

const MessagesPagination = (props: PaginationProps) => <Pagination rowsPerPageOptions={[50, 100, 200]} {...props} />;