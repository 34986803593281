export default (authRoot: string) => {
    let token: string | null = null;
    let isRefreshing: Promise<void> | null = null
    const refreshEndpoint = `${authRoot}/refresh-token`;
    const getToken = async () => {
        if (isRefreshing) {
            await isRefreshing;
            isRefreshing = null;
            return token;
        }
        if (!token) {
            await _reloadRefreshedToken();
        }
        return token;
    }

    const setToken = (newToken: string, delay: number) => {
        token = newToken;
        return true;
    };

    const eraseToken = () => {
        if (!isRefreshing) {
            token = null;
            return true;
        }
        return false;
    }

    const _reloadRefreshedToken = async () => {
        const inner = async () => {
            const data = await _fetchRefreshToken();
            const { token, tokenExpiry } = data
            if (token) {
                setToken(token, tokenExpiry);
                setUser(data)
            } else {
                eraseToken();
            }
        }

        isRefreshing = inner();

        return await isRefreshing;

    }

    const _fetchRefreshToken = () => {
        const request = new Request(refreshEndpoint, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });
        return fetch(request)
            .then((response) => {
                if (response.status !== 200) {
                    return { token: null };
                }
                return response.json();
            })
    }

    let user = undefined;
    const setUser = (val) => {
        user = val;
    }
    const getUser = () => user;

    return {
        getToken,
        setToken,
        eraseToken,
        _reloadRefreshedToken,
        getUser,
        setUser,

    }
}


