
import { stringify } from "query-string";

export default (base: string, params: any = null) => {
    const url = new URL(base);
    if(params){
        url.search = stringify(params)
    }

    return url.toString();
}


// const buildUrl = (base: string, params: any = null) => {
//     const url = new URL(base);
//     if (params) {
//         url.search = new URLSearchParams(params).toString();
//     }
//     return url.toString();
// }