import React, { useState} from 'react';
import {
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    DialogContentText,
    TextField
} from '@material-ui/core';
import {useInput} from 'react-admin';
import {BooleanInput} from "ra-ui-materialui";


export default function AdminBlockedWithConfirmation(props: any) {

    const {input} = useInput({
        source: "adminData.notes"
    });
    const {input:inputToggle} = useInput({
        source: "adminBlocked"
    });

    const [open, setOpen] = useState(false);
    const [reasonsValue, setReasonsValue] = useState(input.value||"");

    const handleToggle = (e: any) => {
        if (!inputToggle.value) {
            setOpen(true)
        }
    };

    const handleChange = (event: any) => {
        setReasonsValue(`${reasonsValue} ${event.target.value}`)
    };

    const handleCancel = () => {
        if (inputToggle.value){
        inputToggle.onChange(false);
        }
        setOpen(false);
    }

    const handleClose = (event: any) => {
        setOpen(false);
         input.onChange( reasonsValue);
    };

    return (
        <>
            <BooleanInput source={props.source} label={props.label} helperText={false} onChange={handleToggle}/>
            <Dialog open={open} onClose={handleClose} aria-labelledby="confirmation-dialog" scroll={'paper'}
                    maxWidth="xs">
                <DialogTitle>Reasons for Block User</DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText>
                        To block this user you have to add one of the reasons or you can choose the 'other' option for
                        typing your own.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Blocking Reasons"
                        type="text"
                        fullWidth
                        onBlur={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button type="submit" onClick={handleClose} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

