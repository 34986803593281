import { useState } from "react";
import { BulkActionProps, ButtonProps } from "react-admin";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import EmailIcon from '@material-ui/icons/Email';
import Button from '@material-ui/core/Button';

import apiRoutes from "../../utils/apiRoutes";
import fetchJson from "../../utils/fetchJson";
import { ToolbarStyledButton } from './ToolbarButton';

const sendEmail = async (topic: string, userId: string, data: any) => {
    const resp = await fetchJson(`${apiRoutes().sendEmail}/${topic}/${userId}`, { method: 'POST', mode: 'cors', body: JSON.stringify(data) })
    console.log(resp)
    return resp;
}

interface Props extends BulkActionProps, ButtonProps {

}

export default (props: Props) => {

    const { record, ...rest } = props;
    const [open, setOpen] = useState<boolean>(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(true);
    };

    const [template, setTemplate] = useState("");
    const handleChange = (event: any) => {
        setTemplate(event.target.value);
    };

    const handleSendEmail = async () => {
        try {
            if (record) {
                const userId = record.id.toString();
                switch (template) {
                    case "registration-verify":
                        await sendEmail(template, userId, { verification: record.verification });
                        break;
                    case "reset-password":
                    case "new-messages":
                    case "new-visitors":
                        alert("no message was sent");
                        break;
                    default:
                        await sendEmail(template, userId, record);
                        break;

                }


            }
        } catch (err) {
            console.log(err);
        };
        // setSelectedIndex(index);
        setOpen(false);
    }

    return (
        <>
            {/* <RaButton label="Email" onClick={handleClick} size='small' variant="outlined" color="primary">
                <EmailIcon /> */}
            {/* //{...sanitizeRestProps(props)} /> */}
            {/* </RaButton> */}
            <ToolbarStyledButton onClick={handleClick}  {...rest}>
                <EmailIcon /> Email
            </ToolbarStyledButton >
            <Dialog open={open}>
                <DialogTitle>Choose template</DialogTitle>
                <DialogContent>
                    <TextField label="Template" placeholder="Type or select template" value={template} />
                    <RadioGroup name="template" value={template} onChange={handleChange}>
                        <FormControlLabel value="registration-verify" control={<Radio />} label="registration-verify" />
                        <FormControlLabel value="reset-password" disabled={true} control={<Radio />} label="reset-password" />
                        <FormControlLabel value="new-messages" disabled={true} control={<Radio />} label="new-messages" />
                        <FormControlLabel value="new-visitors" disabled={true} control={<Radio />} label="new-visitors" />
                        <FormControlLabel value="test1" control={<Radio />} label="test1" />
                        <FormControlLabel value="template1" control={<Radio />} label="template1" />
                        <FormControlLabel value="template2" control={<Radio />} label="template2" />
                        <FormControlLabel value="template3" control={<Radio />} label="template3" />

                    </RadioGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button onClick={handleSendEmail} color="primary">Send</Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

const sanitizeRestProps = ({
    basePath,
    // classes,
    filterValues,
    // label,
    selectedIds,
    ...rest
}: Omit<BulkActionProps, 'resource' | 'icon'>) => rest;
