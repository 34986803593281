
import { Link } from "@material-ui/core";
import get from "lodash/get";
import { TextFieldProps, TextField } from "react-admin";

interface Props extends TextFieldProps {
    type: string
}

export default ({ type, source, record, ...rest }: Props) => {
    if (record && source) {
        const value = get(record, source);
        const typeVal = get(record, type);
        switch (typeVal) {
            case "img": return <Link href={value} target="_blank" {...rest}>[View]</Link>
            case "text": return <TextField source={source} record={record} />
            case "creds": return <TextField source={source} record={record} />
        }
    }
    return null;
}