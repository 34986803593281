import { ImageFieldProps, sanitizeFieldRestProps } from "react-admin";
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import { Typography } from "@material-ui/core";


const useStyles = makeStyles(
    {
        list: {
            display: 'flex',
            listStyleType: 'none',
        },
        image: {
            margin: '0.3rem',
            maxHeight: '4rem',
        },
    },
    { name: 'RaImageField' }
);
export interface MyImageFieldProps extends ImageFieldProps {
    getSrc?: (source: any) => string | undefined
}

export default (props: MyImageFieldProps) => {
    const {
        getSrc = (x) => x,
        className,
        classes: classesOverride,
        emptyText, record, source, src, title, ...rest
    } = props;

    const sourceValue = getSrc(get(record, source!));
    const classes = useStyles(props);
    if (!sourceValue) {
        return emptyText ? (
            <Typography component="span" variant="body2" className={className}
                {...sanitizeFieldRestProps(rest)}
            >
                {emptyText}
            </Typography>
        ) : (
            <div className={className} {...sanitizeFieldRestProps(rest)} />
        );
    }

    const titleValue = get(record, title!) || title;

    return (
        <div className={className} {...sanitizeFieldRestProps(rest)}>
            <img
                title={titleValue}
                alt={titleValue}
                src={sourceValue}
                className={classes.image}
            />
        </div>
    );
}