import React, { useEffect, useState } from "react";
import { List, ListProps, Pagination, PaginationProps } from "ra-ui-materialui";
import { Photo } from "../types";
import PhotoFilter from "./PhotoFilter";
import PhotoGrid from "./PhotoGrid";
import { Modal, ModalProps } from "@material-ui/core";
import photoUrl from "../../utils/photoUrl";
import { PhotoPreview } from "./PhotoPreview";

const PhotoPagination = (props: PaginationProps) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;


export default function PhotoList(props: ListProps) {

    const [preview, setPreview] = useState<Photo | null>(null)
    const handleOnItemSelect = (photo: Photo) => {

        setPreview(photo)
    }

    return (
        <>
            <List {...props}
                sort={{ field: 'updatedAt', order: 'DESC' }}
                pagination={<PhotoPagination />}
                filters={<PhotoFilter />}
                filterDefaultValues={{ moderation: 'any', approved: false }}
                perPage={25}
            >
                <PhotoGrid onItemSelect={handleOnItemSelect} />
            </List>
            <PhotoPreview photo={preview} open={!!preview} onClose={() => setPreview(null)} />
        </>
    );
}




