
import { min, isEqual, subDays, addDays, startOfDay, parse, format, differenceInCalendarDays } from "date-fns";

interface ItemType extends Record<string, any> {
    // [key: string]: Date | string
}

interface Options<T> {
    dateKey?: string,
    initializer: T
}

export function fillDateGaps<T extends Record<string, any>>(items: T[], days: number, options?: Options<T>): T[] {
    const { dateKey = "_id", initializer = null } = options || {}
    if (!items?.length) {
        return items;
    }
    const getDate = (item: T) => item[dateKey];
    const result: T[] = [];
    let j = 0;
    const now = new Date();
    let date = subDays(now, days)
    date = startOfDay(min([getDate(items[0]), date]));

    for (let i = 0; differenceInCalendarDays(now, date) >= 0; i++) {

        if (j < items.length && isEqual(startOfDay(getDate(items[j])), date)) {
            result.push({ ...items[j], [dateKey]: date });
            j++;
        } else {
            result.push({ ...initializer, [dateKey]: date })
        }
        date = addDays(date, 1)

    }
    return result;
}