import { ListProps } from "@material-ui/core";
import {Datagrid, DateField, Filter, List, Pagination, ReferenceField, TextField} from "react-admin";
import { DateTimeField } from "../../User/UserList/UserFields";
import ReportFilter from "../ReportFilter";
import ShowConversationForReportButton from "./ShowConversationForReportButton";
import ShowMessagesForReportButton from "./ShowMessagesForReportButton";


const ReportPagination = (props:any) => <Pagination rowsPerPageOptions={[ 25, 50, 100]} {...props} />;

export default (props: ListProps) => (
    <List {...props}
        perPage={25}
        pagination={<ReportPagination />}
        sort={{ field: 'createdAt', order: 'DESC' }}
        filters={<ReportFilter />}
    >
        <Datagrid rowClick="edit">
            {/* <TextField source="id" /> */}
            <ReferenceField label="Reporter" source="reporter" reference="user">
                <TextField source="profile.nickname" />
            </ReferenceField>
            <ReferenceField label="About" source="about" reference="user">
                <TextField source="profile.nickname" />
            </ReferenceField>
            <ShowConversationForReportButton label="Conversation"/>
            <ShowMessagesForReportButton label="Messages"/>
            <TextField source="subject" />
            {/* <TextField source="body" /> */}
            <DateTimeField source="createdAt" />

        </Datagrid>
    </List>
);
