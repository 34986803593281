import { useQuery } from "@tanstack/react-query";
import apiRoutes from "../utils/apiRoutes";
import { fetchJsonInternal } from "../utils/authProvider2";
import queryString from 'query-string';
import DeferredAggregate from "../utils/DeferredAggregate";

export default function useBlacklistIp() {
    const set = async (clientIp, timeout = 2 * 60 * 60) => {
        const resp = await fetchJsonInternal(`${apiRoutes().blackList}`, { method: 'POST', mode: 'cors', body: JSON.stringify({ clientIp, timeout }) })
        console.log(resp)
        return resp;
    }

    const list = async (ips): Promise<{ ip: string, pttl: number }[]> => {
        const url = queryString.stringifyUrl({ url: `${apiRoutes().blackList}`, query: { ips } })
        const resp = await fetchJsonInternal(url, { method: 'GET', mode: 'cors' })
        console.log(resp)
        return resp.json;
    }



    return {
        set,
        list,

    }
}



const blackListIpBurst = new DeferredAggregate<string, number>(500, async (ips) => {
    const url = queryString.stringifyUrl({ url: `${apiRoutes().blackList}`, query: { ips } })
    const resp = await fetchJsonInternal(url, { method: 'GET', mode: 'cors' })
    console.log(ips, resp)
    return resp.json;
})


export function useBlIpTTL(ip) {
    const { data, isLoading } = useQuery({ queryKey: ["bl-ip-pttl", ip], queryFn: async () => ip ? await blackListIpBurst.requestOne(ip) : null });

    return { ttl: data, isLoading }
}

