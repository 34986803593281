import { TextFieldProps, FieldProps } from "react-admin";
import ReactJsonEditor from "../components/ReactJsonEditor";
import { get } from "lodash";


interface JsonFieldProps extends FieldProps {

}

export default function JsonField(props: JsonFieldProps) {
    const { record, source, className } = props;
    const value = source ? get(record, source) : record;

    return <ReactJsonEditor content={{ json: value }} className={className} readOnly/>

}