import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';


export default ({ record, className }: any) => {
    const handleClick = (e: any) => {
        e.stopPropagation();
    }
    return record ? (
        <Button
            className={className}
            size='small'
            variant="outlined"
            color="primary"
            target="_blank"
            rel="noopener"
            onClick={handleClick}
            component={Link}
            to={{
                pathname: '/message',
                search: stringify({
                    filter: JSON.stringify({ "sender|to": record.id })
                }),
            }}
        >
            Messages
        </Button>
    ) : null;
};
