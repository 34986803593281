import {
    Datagrid,
    DateField,
    List,
    ListProps,
    TextField,
    ReferenceField,
    BooleanField,
    Pagination,
    Filter,
    BooleanInput
} from "react-admin";

const ConversationPagination = (props: any) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const ConversationFilters = (props) =>
    <Filter {...props}>
        <BooleanInput label="Show Deleted" source="withDeleted" alwaysOn />
    </Filter>

export default (props: ListProps) => (
    <List {...props}
        perPage={25}
        pagination={<ConversationPagination />}
        filter={{ lastSent: true }}
        filters={<ConversationFilters />}
        sort={{ field: 'updatedAt', order: 'DESC' }}
    >
        <Datagrid rowClick="show">
            <TextField source="id" />
            {/* <TextField source="usersInbox[0]._id"/> */}
            <ReferenceField label="U1" source="usersInbox[0]._id" reference="user">
                <TextField source="profile.nickname" />
            </ReferenceField>
            {/* <TextField source="usersInbox[0].sent" /> */}

            {/* <TextField source="usersInbox[1]._id"/> */}
            <ReferenceField label="U2" source="usersInbox[1]._id" reference="user">
                <TextField source="profile.nickname" />
            </ReferenceField>
            {/* <TextField source="usersInbox[1].sent" /> */}

            {/* <TextField source="usersInbox[0]._id"/>
            <TextField source="usersInbox[1]._id"/> */}

            {/* <NumberField source="count" /> */}

            <DateField source="updatedAt" />
            {/* <TextField source="lastMessage" /> */}
            {/* <DateField source="lastSent" /> */}
            <DateField source="createdAt" />
            <BooleanField source="deleted" />
            {/* <ArrayField source="usersInbox"><SingleFieldList><ChipField source="unread" /></SingleFieldList></ArrayField> */}
            {/* <NumberField source="unread" /> */}

        </Datagrid>
    </List>
);