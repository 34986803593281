import { Box, Card, CardContent, Grid, Slider } from "@material-ui/core"
import { Title, useAuthState } from "react-admin"
import ActiveUsers from "./ActiveUsers"
import RegistrationsChart from "./RegistrationsChart"
import TimezoneSelect from "react-timezone-select";

import Totals from "./Totals";
import UsersByCity from "./UsersByCity2";
import RetentionByClaims from "./RetentionByClaims"
import { useLocalStorage } from '../utils/useLocalStorage';
import DepositsByDay from "./DepositsByDay";
import DepositsByMonth from "./DepositsByMonth";
import { useState } from "react";
import { NewByGenderClid } from "./NewByGenderClid";



export default () => {

    const { loading, authenticated } = useAuthState();
    const [timezone, setTimezone] = useLocalStorage("timezone", Intl.DateTimeFormat().resolvedOptions().timeZone);
    
    return (
        <div>
            {authenticated &&
                <Card>
                    <Title title="Welcome to the administration" />
                    <CardContent>
                        <TimezoneSelect value={timezone} onChange={(value) => setTimezone(value.value)} />
                        {/* <div>{timezone}-{utcOffset}---{}</div> */}
                        <Grid container spacing={1}>
                            {/* <Grid item xs={12} sm={12} md={6}>
                                <RetentionByClaims />
                            </Grid> */}
                            <Grid item xs={12} sm={12} md={6}>
                                <Totals timezone={timezone} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <ActiveUsers timezone={timezone} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <RegistrationsChart timezone={timezone} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <NewByGenderClid timezone={timezone} />
                            </Grid>
                            
                            <Grid item xs={12} sm={12} md={6}>
                                <DepositsByDay timezone={timezone} />

                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <DepositsByMonth timezone={timezone} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <UsersByCity timezone={timezone} />
                            </Grid>

                        </Grid>
                        <Grid container spacing={1}>

                        </Grid>

                    </CardContent>
                </Card>
            }
        </div>
    )
}