import { useEffect, useMemo, useState } from "react";
import { useAuthenticated } from "react-admin";
import NavigationRefresh from "@material-ui/icons/Refresh";
import { Area, Bar, BarChart, Brush, CartesianGrid, ComposedChart, LabelList, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import SwitchSelector from "react-switch-selector";

import apiRoutes from "../utils/apiRoutes";
import fetchJson from "../utils/fetchJson";
import chatConfig from './chartConfig'
import buildQueryUrl from "../utils/buildQueryUrl";
import ToggleButtonsMultiple from "./ToggleButtonsMultiple";
import {useQuery} from "@tanstack/react-query";
import {IconButton} from "@material-ui/core";


// const buildUrl = (base: string, params: any = null) => {
//     const url = new URL(base);
//     if (params) {
//         url.search = new URLSearchParams(params).toString();
//     }
//     return url.toString();
// }

interface ICityUsers {
    _id: string,
    total: number,
    male: number,
    female: number,
    sponsor: number,
    city: string
}

const timeFrameOptions = [
    { value: 0, label: "today" },
    { value: 1, label: "1 day" },
    { value: 2, label: "2 days" },
    { value: 3, label: "3 days" },
    { value: 7, label: "7 days" },
    { value: 14, label: "14 days" },
    { value: 30, label: "30 days" },
    { value: 60, label: "60 days" },
    { value: 90, label: "90 days" },
    // { value: 180, label: "180 days" },
    { value: 365, label: "365 days" }
]

interface Props {
    timezone: string,
    days?: number
}

export default ({ timezone }: Props) => {

    const [days, setDays] = useState(1);


    const {data,refetch} = useQuery<ICityUsers[]>(
        ['usersByCity', days, timezone],
        () => fetchJson(buildQueryUrl(apiRoutes().dashboard.usersByCity, {days, timezone}))
            .then(resp => {
                return resp.json}),
        {
            staleTime: Infinity,
            refetchOnWindowFocus: false
        }
    );

    const handleTimeFrameChange = (value: any) => {
        setDays(value);
    };

    const barLabelFormatter = (value: number) => value > 1 ? value : ""
    const totalLabelFormatter = (value: number) => value;

    // function scale(y: any) {
    //     return Math.round(y % 5) * 5;
    // }


    return (
        <div>
            <IconButton onClick={() => refetch()} aria-label="refresh" color="primary"><NavigationRefresh/></IconButton>
            {/* <SwitchSelector
                optionBorderRadius={6} wrapperBorderRadius={6}
                selectionIndicatorMargin={1}

                options={timeFrameOptions} onChange={handleTimeFrameChange}
                name="user-by-city-timeframe" initialSelectedIndex={0} /> */}

            <ToggleButtonsMultiple options={timeFrameOptions} value={days} onChange={handleTimeFrameChange} />

            <ResponsiveContainer width="100%" height={480}>
                <ComposedChart data={data?.slice(0, 20)}
                    layout="vertical"
                    barGap={1}
                    barCategoryGap={0}
                    margin={{ top: 5, right: 50, bottom: 5, left: 10 }}
                // reverseStackOrder={false}
                >
                    <CartesianGrid strokeDasharray="1 3" />
                    <XAxis type="number" />
                    <YAxis dataKey="city" type="category" width={100} fontSize="14px" interval={0} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="male" stackId="a" fill={chatConfig.male.color} barSize={14}>
                        <LabelList dataKey="male" fontSize="14px" fill="white" />
                    </Bar>
                    <Bar dataKey="female" stackId="a" fill={chatConfig.female.color} barSize={14} >
                        <LabelList dataKey="female" fontSize="14px" fill="white" />
                        <LabelList dataKey={(o: any) => `(${o.sponsor})/${o.total}`} fontSize="14px" fill='black' position="right" formatter={totalLabelFormatter} />
                    </Bar>
                    <Bar dataKey="sponsor" fill={chatConfig.sponsor.color} barSize={4} stroke='#b5aeae' >
                    </Bar>
                </ComposedChart>
            </ResponsiveContainer>

        </div >
    )
}

