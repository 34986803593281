import { Box, IconButton, Slider, Typography } from "@material-ui/core";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    BarChart,
    Bar,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import moment from "moment-timezone";

import apiRoutes from "../utils/apiRoutes";
import buildQueryUrl from "../utils/buildQueryUrl";
import fetchJson from "../utils/fetchJson";
import NavigationRefresh from "@material-ui/icons/Refresh";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";

interface Props {
    timezone: string,
    // months?: number
}

const colors: string[] = ['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB', '#EB9694', '#FAD0C3', '#FEF3BD', '#C1E1C5', '#BEDADC', '#C4DEF6', '#BED3F3', '#D4C4FB'];
const currencies = ["USD", "EUR", "GBP", "USDT", "BUSD"] as const
type Currencies = typeof currencies[number]
type CurrencyValues = Record<Currencies, number>

interface FetchedItem {
    amount: number,
    count: number,
    _id: {
        currency: Currencies,
        date: string
    }
}

interface DatedRecord {
    date: Date,
    amount: CurrencyValues,//{USD:135,EUR:130}
    count: CurrencyValues
}

//{USD:0,EUR:0,GBP:0}
const initCurrencyValues = () => currencies.reduce((acc, currency) => ({ ...acc, [currency]: 0 }), {} as CurrencyValues)

export default function DepositsByMonth({ timezone }: Props) {
    const [months, setMonths] = useState(12)
    const startDate = moment().subtract(months - 1, "month").tz(timezone).startOf("month");
    const templateMonthArray: DatedRecord[] = Array.from({ length: months }, (_, i) => {
        const result: DatedRecord = {
            date: startDate.toDate(),
            amount: initCurrencyValues(),
            count: initCurrencyValues()
        };
        startDate.add(1, 'month');
        return result;
    });

    const { data, refetch } = useQuery<DatedRecord[]>(
        ['months', timezone, months],
        () => fetchJson(buildQueryUrl(apiRoutes().dashboard.depositsByMonth, { months, timezone }))
            .then(resp => {
                const json: FetchedItem[] = resp.json;
                const datedRecordsDictionary: Record<string, DatedRecord> = {};

                for (const { _id: { currency, date }, amount, count } of json) {
                    const key = moment(date).tz(timezone).format("YYYY-MM-DD");
                    if (!datedRecordsDictionary[key]) {
                        datedRecordsDictionary[key] = {
                            date: moment(date).toDate(),
                            amount: initCurrencyValues(),
                            count: initCurrencyValues()
                        }
                    }
                    datedRecordsDictionary[key].amount[currency] = amount;
                    datedRecordsDictionary[key].count[currency] = count;
                }

                const res = templateMonthArray.map((template: DatedRecord) => {
                    const key = moment(template.date).tz(timezone).format("YYYY-MM-DD");
                    const newValue = datedRecordsDictionary[key];
                    return newValue || template;
                });
                return res;
            }),
        {
            staleTime: Infinity,
            refetchOnWindowFocus: false
        }
    );

    const dataForRender = useMemo(() => data?.map(({ date, amount }) => ({ date, ...amount })) || [], [data])

    return (
        <Box>
            <IconButton onClick={() => refetch()} aria-label="refresh" color="primary"><NavigationRefresh /></IconButton>
            <Typography variant="h6" align="center">Deposits By Month</Typography>
            <ResponsiveContainer width="100%" height={220}>

                <BarChart
                    data={dataForRender}
                    margin={{ top: 20, right: 10, left: -20, bottom: 0 }}
                    barCategoryGap={"1%"}
                    barGap={1}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" tick={{ fontSize: 12 }}
                        tickFormatter={(value) => (!value || value === "auto") ? value : moment(value).format('MMM Y')} />
                    <YAxis yAxisId="left" orientation="left" stroke="#B0B5B3" tick={{ fontSize: 12 }} />
                    <Tooltip labelFormatter={(value: any) => moment(value).format('MMM Y')} />
                    <Legend />
                    {currencies.map((currency, index) => <Bar yAxisId="left" type="monotone" dataKey={currency}
                        key={currency} fill={colors[index]} />)}
                </BarChart>
            </ResponsiveContainer>
            <Box px={"15%"}>
                <Slider
                    value={months}
                    onChange={(e, newValue) => setMonths(newValue as number)}
                    marks={[{ value: 12, label: "12" }, { value: 24, label: "24" }, { value: 36, label: "36" }]}
                    // valueLabelDisplay="on"
                    step={null}
                    min={10} max={40}
                />
            </Box>
        </Box>
    )
};
