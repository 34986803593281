import {IconButton } from "@material-ui/core"

import ImageFieldExt, {MyImageFieldProps} from './ImageFieldExt';
import photoUrl from '../utils/photoUrl';

const ImageFieldExtWithLink = (props: MyImageFieldProps) => {
    
const {record} = props;

    return (
        <div>
            <IconButton href={photoUrl(typeof record?.id === "string" ? record?.id : (record?._id|| record?.id), "full")} target="_blank">
            <ImageFieldExt {...props} />
            </IconButton>
        </div>
    );
};

export default ImageFieldExtWithLink;