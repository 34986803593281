import { BooleanField, Datagrid, DateField, List, ListProps, NumberField, TextField } from "react-admin";
import AnnounceFilter from "./AnnounceFilter";
const AnnounceList = (props: ListProps) => (
    <List { ...props }
              perPage={ 25 }
              sort={ { field: 'createdAt', order: 'DESC' } }
              filters={ <AnnounceFilter/> }>
        <Datagrid rowClick="edit">
            <BooleanField source="active" />
            <TextField source="type" />
            <TextField source="language" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
            <DateField source="startsOn" />
            <DateField source="endsOn" />
            <TextField source="content" />
            <NumberField source="delay" />
        </Datagrid>
    </List>
);

export default AnnounceList;