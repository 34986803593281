import { ImageField, ImageInput } from "react-admin";




function format(value: any) {
    if (!value || typeof value === "string") { // Value is null or the url string from the backend, wrap it in an object so the form input can handle it 
        return { url: value };
    } else {  // Else a new image is selected which results in a value object already having a preview link under the url key
        return value;
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function ImageInputSafe(props: any) {
    return <ImageInput format={format} {...props}>
        <ImageField source="url" />
    </ImageInput>
}