import { Edit, EditProps, FormWithRedirect, FormWithRedirectRenderProps, InputProps, SimpleForm, useEditController, useInput, useNotify, useRedirect, useRefresh } from "react-admin";
import ReactJsonEditor from "../components/ReactJsonEditor";

import { Content } from "vanilla-jsoneditor";


export const SettingEdit = (props: EditProps) => {
    // const controllerProps = useEditController(props);
    // const { loaded, record } = controllerProps;
    const refresh = useRefresh();
    const notify = useNotify();
    const redirect = useRedirect();
    const { basePath,id } = props;
    return (
        <Edit {...props} onSuccess={() => {
            // notify('ra.notification.created', {  messageArgs: { smart_count: 1 } });
            notify('Setting updated');
            redirect('edit', basePath,id);
        }}>
            {/* {loaded && <ReactJsonEditor content={{ json: record }} />} */}
            {/* <FormWithRedirect {...props} render={(formProps: FormWithRedirectRenderProps) => 
                <>
                    {loaded && <ReactJsonEditor content={{ json: formProps.record }} />}
                </>
            }/> */}
            <SimpleForm>
                <JsonSettingsInput source="." />
            </SimpleForm>
        </Edit>
    );
}

const JsonSettingsInput = (props: InputProps) => {
    const { input, meta: { error, submitError, touched }, id, isRequired, } = useInput(props)

    const handleChange = (content: any, previousContent: Content, OnChangeStatus: any) => {

        if (content.json) {
            input.onChange(content.json)
        } else {
            input.onChange(JSON.parse(content.text))
        }
    }

    return (
        <ReactJsonEditor
            content={{ json: input.value }}
            onChange={handleChange}
            className={props.className}
            style={{ width: "100%" }}
        />
    )
}