import { useContext } from "react";
import { ButtonProps, ShowButton, linkToRecord, DataProviderContext } from "react-admin";
import { Link, useHistory } from "react-router-dom";
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined';
import { IconButton, Button } from "@material-ui/core";
import { stringify } from "query-string";


const singleItem = { pagination: { page: 1, perPage: 1 }, sort: { field: "id", order: 'DESC' } };


export default ({ record }: ButtonProps) => {

    const dataProvider = useContext(DataProviderContext);
    const history = useHistory();
    const handleClick = async (e: any) => {
        e.stopPropagation();
        if (record) {
            const users = [record.reporter, record.about];
            const link = `/#/message?${stringify({ filter: JSON.stringify({ users }) })}`;
            window.open(link,"_blank")
            
            // history.push({
            //     pathname: '/#/message',
            //     search: `${stringify({ filter: JSON.stringify({ users }) })}`,

            //     // state: { _scrollToTop: true },
            // });



        }
    }

    // if (record) {
    //     const users = [record.reporter, record.about];
    //     return (
    //         <Button component={Link} to={{
    //             pathname: '/message',
    //             search: `${stringify({ filter: JSON.stringify({ users }) })}`,
    //         }}>
    //             <MessageOutlinedIcon />
    //         </Button>
    //     )
    // } else {
    //     return (<></>)
    // }

    return (
        <IconButton onClick={handleClick} >
            <MessageOutlinedIcon />
        </IconButton>)
}