import React from "react";
import {BooleanInput, Filter, FilterProps, TextField, TextInput, SelectInput, ReferenceField} from "ra-ui-materialui";
import {AutocompleteInput, ReferenceInput, SelectInput as SelectInputRA} from 'react-admin';
import { LANGUAGES } from "../../utils/constants";

const UserFilter = (props: any) => (
    <Filter {...props}>
        {/* <TextInput label="Search" source="q" alwaysOn /> */}
        <TextInput label="Email" source="email" alwaysOn/>
        <TextInput label="Nickname" source="profile.nickname" alwaysOn/>
        <SelectInput label="Valid" source="profileValid" alwaysOn fullWidth choices={[
            {id: 'true', name: "Yes"},
            {id: 'false', name: "No"},
        ]}/>
         <ReferenceInput label="City" source="profile.city" reference="city"
                         filterToQuery={txt => ({name: txt})}
                         sort={{field:"name", order:"ASC"}} alwaysOn>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <BooleanInput label="Deleted" source="withDeleted" alwaysOn/>
        <SelectInput label="Language" source='settings.uiLang' fullWidth choices={LANGUAGES}/>

        <SelectInput label="Verified" source="verified" fullWidth choices={[
            {id: 'true', name: "Yes"},
            {id: 'false', name: "No"},
        ]}/>

        <SelectInput label="Blocked" source="adminBlocked" fullWidth choices={[
            {id: 'true', name: "Yes"},
            {id: 'false', name: "No"},
        ]}/>

        <SelectInput label="Gender" source='profile.gender' fullWidth choices={[
            {id: 'male', name: "Male"},
            {id: 'female', name: "Female"},
        ]}/>
        <SelectInput label="Is Sponsor" source="profile.isSponsor" fullWidth choices={[
            {id: 'true', name: "Yes"},
            {id: 'false', name: "No"},
        ]}/>
        <SelectInput label="Premium" source="premium.isActive" fullWidth choices={[
            {id: 'true', name: "Yes"},
            {id: 'false', name: "No"},
        ]}/>

        {/* <ReferenceInput label="User" source="userId" reference="users" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput> */}
    </Filter>
)


export default UserFilter;
