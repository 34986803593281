import {
    AutocompleteInput,
    SelectArrayInput,
    Filter,
    ReferenceInput,
    SelectInput,
    TextInput
} from 'react-admin';
import {DateInput} from "ra-ui-materialui";


const TransactionFilter = (props: any) => (
    <Filter {...props}>

        <ReferenceInput label="User" source="user" reference="user" allowEmpty alwaysOn
                        filterToQuery={txt => ({profile: {nickname: txt}})}>
            <AutocompleteInput optionText="profile.nickname"/>
        </ReferenceInput>
        <TextInput label="id" source="id" alwaysOn/>
        <TextInput label="Ref" source="refId" alwaysOn/>
        <ReferenceInput label="product" source="product" reference="product" allowEmpty>
            <AutocompleteInput optionText="title"/>
        </ReferenceInput>
        <SelectInput source="currency" label="currency" allowEmpty choices={[
            {id: 'USD', name: 'USD'},
            {id: 'EUR', name: 'EUR'},
            {id: 'GBP', name: 'GBP'}
        ]}/>
        <SelectArrayInput label="status" source="status" choices={[
            {id: 'init', name: 'init'},
            {id: 'complete', name: 'complete'},
            {id: 'failed', name: 'failed'},
            {id: 'pending', name: 'pending'}
        ]}>
        </SelectArrayInput>
        <SelectInput label="type" source="type" allowEmpty choices={[
            {id: 'payment', name: 'payment'},
            {id: 'chargeback', name: 'chargeback'},
            {id: 'cancelation', name: 'cancelation'},
            {id: 'expired', name: 'expired'}
        ]}/>
        <SelectInput label="is test" source="isTest" fullWidth choices={[
            {id: 'true', name: "Yes"},
            {id: 'false', name: "No"},
        ]}/>
        <DateInput source="updatedAt" allowEmpty/>
        <SelectInput label="provider" source="provider" allowEmpty choices={[
            {id: 'myppk', name: 'MyPapik'},
            {id: 'walletto', name: 'Walletto'},
            {id: 'maxpay', name: 'MaxPay'},
            {id: 'binance', name: 'Binance'}
        ]}/>

    </Filter>
);

export default TransactionFilter;