import { BooleanField, BooleanInput, Create, Datagrid, Edit, EditProps, ImageField, List, NullableBooleanInput, NumberField, NumberInput, SimpleForm, TextField, TextInput } from "react-admin";

import './Moods.css'
import ImageInputSafe from "../components/ImageInputSafe";
import { LANGUAGES } from "../utils/constants";
import { DurationInput } from "../components/DurationInput";

export const MoodList = props => (
    <List {...props} perPage={25} sort={{ field: 'order', order: 'ASC' }}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <BooleanField source="enabled" />
            <NumberField source="durationSec" label={<s>duration sec</s>} />
            <TextField source="duration" />

            <NumberField source="order" />

            <ImageField source="imageUrl" label={"Image"} cellClassName="imageCell" />
        </Datagrid>
    </List>
);


export const MoodEdit = ({ ...props }: EditProps) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled fullWidth />
            <NullableBooleanInput source="enabled" />

            {/* <NumberInput source="durationSec" helperText="obsolete" /> */}
            {/* <TextInput source="duration"  helperText={"1d, 12h, 30min"} /> */}
            <DurationInput source="duration" isRequired />
            <NumberInput source="order" />
            <ImageInputSafe source="imageUrl" accept="image/*" multiple={false} />
            <TextInput source="imageUrl" fullWidth />
            {LANGUAGES.map(({ id, name }) => <TextInput key={id} label={name} source={`text.${id}`} fullWidth />)}
        </SimpleForm>
    </Edit>
);

export const MoodCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="id" fullWidth />
            <NullableBooleanInput source="enabled" />
            {/* <NumberInput source="durationSec" helperText="obsolete" /> */}
            {/* <TextInput source="duration" helperText={"1d, 12h, 30min"} /> */}
            <DurationInput source="duration" />
            <NumberInput source="order" />
            <ImageInputSafe source="imageUrl" accept="image/*" multiple={false} />
            <TextInput source="imageUrl" fullWidth />
            {LANGUAGES.map(({ id, name }) => <TextInput key={id} label={name} source={`text.${id}`} fullWidth />)}
        </SimpleForm>
    </Create>
);