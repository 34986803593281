import React from "react"
import { ArrayField, Datagrid, List, ListProps, TextField } from "react-admin"
import ActivateBulkButton from "./ActivateBulkButton"
import { DateTimeField } from "../User/UserList/UserFields"

const QueryList = (props: ListProps) => {

    return (
        <List
            sort={{ field: 'id', order: 'DESC' }}
            {...props}>
            <Datagrid rowClick="edit">
                <TextField source="name" />
                <ActivateBulkButton label="Activate" />
                <TextField source="description" />
                <TextField source="template" />
                <TextField source="modifiedAt" />
                <TextField source="query" />
                <ArrayField source="sent" >
                    <Datagrid>
                        <DateTimeField source="time" split />
                        <TextField source="count" />

                    </Datagrid>
                </ArrayField>
            </Datagrid>
        </List>
    )
}

export default QueryList;