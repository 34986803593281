import React from 'react';
import {makeStyles, createStyles, Theme, fade} from '@material-ui/core/styles';

import getVideoUrl from '../../utils/videoUrl';

interface Props {
    media: { id: string },
    playsInline?: boolean,
    autoPlay?: boolean,
    loop?: boolean,
    muted?: boolean,
    className?: string,
    onCloseClick?: () => void,
    controls?: boolean
}

const useStyles = makeStyles((theme: Theme) => createStyles({

    root: {
        position: "relative"
    },
    video: {
        width: "100%",
        height: "100%"
    },
    playButton: {
        position: "absolute",
        left: 0,
        bottom: 0,
        color: fade(theme.palette.common.white, 0.9)

    },
    closeButton: {
        position: "absolute",
        left: 0,
        top: 0,
        color: fade(theme.palette.common.white, 0.9)
    },
    icon: {
        fontSize: "48px"
    },
    spinnerWrapper: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    }

}));

const VideoPlayer = (props: Props) => {

    const classes = useStyles();
    const {media, playsInline = true, autoPlay = false, loop = false, muted = true, controls = true} = props;

    return (
            <video controls={controls} key={media.id}
                   className={classes.video}
                   muted={muted} loop={loop} autoPlay={autoPlay} playsInline={playsInline}
            >
                <source src={getVideoUrl(media, "video/webm")} type={"video/webm"}/>
                <source src={getVideoUrl(media, "video/mp4")} type={"video/mp4"}/>
            </video>
    );
};

export default VideoPlayer;