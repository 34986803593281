
import { useInput } from "react-admin"
import { Content, JSONEditor, JSONEditorPropsOptional } from "vanilla-jsoneditor";
import ReactJsonEditor from "../components/ReactJsonEditor";


// eslint-disable-next-line import/no-anonymous-default-export
export default function JsonInput(props: any) {

    const { input, meta, id, isRequired } = useInput({
        ...props,
        // format: (v: any) => JSON.parse(v),
        // parse: (v: any) => JSON.stringify(v)
    })

    const handleChange = (content: any, previousContent: Content, OnChangeStatus: any) => {

        if (content.json) {
            input.onChange(JSON.stringify(content.json))
        } else {
            input.onChange(content.text)
        }
    }

    return (<ReactJsonEditor
        content={{ text: input.value }}
        onChange={handleChange}
        className={props.className}
    />)
}

