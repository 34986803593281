import { Button, ButtonProps } from '@material-ui/core';
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Record } from 'react-admin';
import { Link, LinkProps } from 'react-router-dom';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            flexBasis: '104px',
            alignSelf: 'auto',
            height: '24px',
            padding: theme.spacing(0, 0.5),
            fontSize: '0.7rem',
            border: `1px solid ${theme.palette.primary.dark}`,
            margin: '5px',
            backgroundColor: theme.palette.primary.contrastText
        },
     })
);

export const ToolbarStyledButton = <D extends React.ElementType = 'button'>(props: ButtonProps<D>) => {
    const classes = useStyles();
    return <Button classes={classes} {...props} color="primary" variant='outlined' />
}

interface Props extends Pick<LinkProps, 'to'> {
    label: string,
    record: Record
}

export const ToolbarLinkButton = (props: Props) => {
    const { label } = props;
    return (
        <ToolbarStyledButton<Link>
            component={Link}
            {...props}
        >
            {label}
        </ToolbarStyledButton>
    )
}