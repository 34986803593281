import { useMemo, useState } from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import moment from "moment-timezone";
import { useQuery } from "@tanstack/react-query";
import NavigationRefresh from '@material-ui/icons/Refresh';
import { Box, IconButton, Slider, Typography } from "@material-ui/core";

import apiRoutes from "../utils/apiRoutes";
import fetchJson from "../utils/fetchJson";
import chatConfig from './chartConfig'
import buildQueryUrl from "../utils/buildQueryUrl";

interface ISample {
    x: Date,
    y: number
}

interface IDayRecord extends Record<string, any> {
    _id: string;
    total: number,
    female: number;
    male: number;
    sponsor: number;
    profiles: number;
}

function extendFullWithDateRange(items: IDayRecord[], days: number): IDayRecord[] {
    items = items.map(({ profiles, ...itm }) => ({ profiles: itm.female + itm.male, ...itm }))
    const result: IDayRecord[] = [];
    let j = 0;
    let date = moment().subtract(days, "days").startOf("day");
    date = moment.min(moment(items[0]._id), date);
    const now = moment().startOf("day");
    for (let i = 0; now.diff(date, "days") >= 0; i++) {
        const dateKey = date.format('YYYY-MM-DD');
        if (j < items.length && items[j]._id === dateKey) {
            result.push({ date: date.toDate(), ...items[j] });
            j++;
        } else {
            result.push({ _id: dateKey, date: date.toDate(), total: 0, female: 0, male: 0, sponsor: 0, profiles: 0 })
        }
        date.add(1, 'days');
    }
    return result;
}

interface Props {
    timezone: string,
   
}

export default ({ timezone }: Props) => {
    const [days, setDays] = useState(30);
    const { data, refetch } = useQuery<IDayRecord[]>(
        ['new-by-date', days, timezone],
        () => fetchJson(buildQueryUrl(apiRoutes().dashboard.newByDate, { days, timezone }))
            .then(resp => {
                return resp.json
            }),
        {
            staleTime: Infinity,
            refetchOnWindowFocus: false
        }
    );

    const keys = ["total", "male", "female", "sponsor", "profiles"];

    const itemsExtended = useMemo(() => data?.length ? extendFullWithDateRange(data, days) : [], [data]);

    // console.log(data, itemsExtended);
    const colors = keys.map(key => chatConfig[key].color);

    return (
        <Box>
            <IconButton onClick={() => refetch()} aria-label="refresh" color="primary"><NavigationRefresh /></IconButton>
            <Typography variant="h6" align="center">Registrations</Typography>
            <ResponsiveContainer width="100%" height={220}>
                <LineChart data={itemsExtended} margin={{ top: 20, right: 10, left: -20, bottom: 0 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="_id"
                        tick={{ fontSize: 12 }}
                        tickFormatter={(value) => (!value || value === "auto") ? value : moment(value).format('D MMM')}
                    />
                    <YAxis tick={{ fontSize: 12 }} />
                    <Tooltip />
                    <Legend />
                    {keys.map(key =>
                        <Line key={key} dataKey={key}
                            dot={{ r: 3 }}
                            strokeWidth={2} stroke={chatConfig[key].color} activeDot={{ r: 4 }} />)
                    }
                </LineChart>
            </ResponsiveContainer>
            <Box px={"15%"}>
                <Slider
                    value={days}
                    onChange={(e, newValue) => setDays(newValue as number)}
                    marks={[{ value: 30, label: "30" }, { value: 3 * 30, label: "90" }, { value: 6 * 30, label: "180" }, { value: 365, label: "365" }]}
                    // valueLabelDisplay="on"
                    step={null}
                    min={20} max={380}
                />
            </Box>
        </Box>
    )
}