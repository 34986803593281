
import UriTemplate from 'es6-url-template';

const elements = [
    "hash",
    "host",
    "hostname",
    "href",
    "origin",
    "password",
    "pathname",
    "port",
    "protocol",
    "search",
    "searchParams",
    "username",
]



const relativeUrl = (path: string, locationHref?: string): string => {

    const url = new URL(locationHref ? locationHref : window.location.href) as any;
    const template = new UriTemplate(path);

    const urlElements = elements.reduce((obj, key) => url[key] ? ({ ...obj, [key]: url[key] }) : obj, {})

    const result = template.expand(urlElements);
    return result;
}


export default relativeUrl;
export const getApiRootUrl = (locationHref?: string) => relativeUrl(`${import.meta.env.VITE_ADMINAPI_ROOT}`, locationHref);
export const getPhotoRootUrl = (locationHref?: string) => relativeUrl(`${import.meta.env.VITE_PHOTO_ROOT}`, locationHref);
