import { ArrayField, BooleanField, Datagrid, DateField, EmailField, FieldProps, NumberField, ReferenceField, Show, SimpleShowLayout, TextField, useGetOne } from "react-admin";
import JsonField from "./JsonField";
import { Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import sendEvent from "../utils/sendEvent";



export default function TransactionShow(props) {

    return (
        <Show {...props} >

            <SimpleShowLayout>
                <TextField source="id" />
                <WallettoRebillButton />
                <TextField source="refId" />
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
                <TextField source="provider" />

                <ReferenceField label="User" source="user" reference="user">
                    <TextField source="profile.nickname" />
                </ReferenceField>
                <ReferenceField label="Product" source="product" reference="product">
                    <TextField source="title" />

                </ReferenceField>
                <ReferenceField label="Product Type" source="product" reference="product">
                    <TextField source="type" />
                </ReferenceField>
                <ReferenceField label="Recurrent" source="product" reference="product" link={false}>
                    <TextField source="autoRenewal" />
                </ReferenceField>
                {/* <TextField source="product" /> */}
                <TextField source="status" />
                <BooleanField source="isTest" />
                <TextField source="type" />
                <NumberField source="amount" />
                <TextField source="currency" />

                <ArrayField source="dataList">
                    <Datagrid>
                        <JsonField />
                        {/* <TextField source="order" />
                        <TextField source="user" />
                        <EmailField source="email" />
                        <TextField source="item_name" />
                        <TextField source="currency" />
                        <NumberField source="amount" />
                        <DateField source="createdAt" /> */}
                    </Datagrid>
                </ArrayField>


                <JsonField source="private" />

            </SimpleShowLayout>
        </Show>
    )
}


const WallettoRebillButton = (props: FieldProps) => {
    const { record } = props;


    const { provider } = record;
    const { data: productData } = useGetOne('product', record.product)
    const { data: userData } = useGetOne('user', record.user);

    const [disabled, setDisabled] = useState(false)
    // const user = useGetOne('user', record.user);
    if (provider !== "walletto" || !productData || !userData)
        return null
    if (productData?.type == "subscription" && productData?.autoRenewal && userData.premium?.isActive) {

        return <Button variant="outlined" color="primary" disabled={disabled} onClick={() => {
            setDisabled(true)
            sendEvent("invoke-event", "post", {
                data: {
                    path: '/payment/callback/walletto',
                    body: {
                        command: "rebill",
                        userId: userData.id
                    }
                }
            })
        }}>Rebill</Button>
    } else
        return null
}