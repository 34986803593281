import { useState, ChangeEvent, useEffect } from "react";
import { InputAdornment, TextField, Button } from "@material-ui/core";

const param = {
    "url": "https://api.freecurrencyapi.com/v1/latest",
    "apikey": "rI2tOGCXUNNHtI2RSG4HrHhYhrsvK45LHe8RBj9N",
    "base_currency": "USD",
    "currencies": "USD,EUR,GBP"
};

const knownCurrencies = ["USD", "EUR", "GBP"];
param.currencies = knownCurrencies.join();


export default () => {

    const [calculatorIsOpen, setCalculatorIsOpen] = useState<boolean>(false);

    const handleOpenCalc = () => {
        setCalculatorIsOpen(oldValue => !oldValue)
    };

    return (
        <div>
            <Button variant="outlined" onClick={handleOpenCalc}>Calculator</Button>
            {calculatorIsOpen && <PriceCalculator />}
        </div>
    )
}

const PriceCalculator = (props: any) => {

    const [prices, setPrices] = useState<Record<string, string>>({});
    const [rates, setRates] = useState<Record<string, number>>();
    const getCurrencyRate = (): void => {
        fetch(`${param.url}?apikey=${param.apikey}&base_currency=${param.base_currency}&currencies=${param.currencies}`)
            .then(response => response.json())
            .then(data => setRates(data.data));
    }

    useEffect(() => {
        getCurrencyRate();
    }, [])


    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {

        if (rates) {
            const newPrices: Record<string, string> = {};
            knownCurrencies.forEach(currency => {
                const { name, value } = e.target;
                if (name !== currency) {
                    newPrices[currency] = ((rates[currency] * Number(value)) / rates[name]).toFixed(2);
                }
            })

            setPrices(newPrices)
        }
    }

    return (
        <div>
            {knownCurrencies.map((currency: any) => <TextField
                name={currency}
                key={currency}
                value={prices[currency]}
                onChange={handleChange}
                variant={"filled"}
                label={'calculator'}
                margin={'dense'}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {currency}{" "}
                        </InputAdornment>
                    ),
                }}
            />)}
        </div>
    );
};
