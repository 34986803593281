import { Grid } from "@material-ui/core"
import { useListContext, useMutation, useNotify, useRefresh, useUpdate } from "ra-core";
import React from "react";
import { Identifier } from "react-admin";
import { isPropertySignature } from "typescript";
import { Photo } from "../types";
import PhotoCard from "./PhotoCard"

type Props = {
    onItemSelect?: (data: Photo) => void
}

export default React.forwardRef<any, any>(({ onItemSelect, ...props }: Props, ref) => {


    const { data: photos, ids, basePath, page } = useListContext<Photo>();

    const notify = useNotify();
    const [mutate, { loading }] = useMutation()
    const updatePhoto = (pid: Identifier, data: Partial<Photo>) => {
        mutate({
            type: 'update', resource: 'photo',
            payload: { id: pid, data }
        }, {
            mutationMode: 'optimistic',//'undoable',
            onSuccess: () => {
                notify('Moderated', 'info', {}, true);
            }
        })
    }


    // console.log({ photos, ids });
    return (
        <Grid container spacing={2} ref={ref}>
            {ids.map((id) => {
                const photo = photos[id];
                return (
                    <Grid key={id} item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <PhotoCard photo={photo}
                            onItemSelect={() => { onItemSelect?.(photo) }}
                            onUpdate={(pdata) => updatePhoto(photo.id, pdata)}
                        />
                    </Grid>
                )
            })}
        </Grid>
    )

})