
import { Bar, BarChart, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useQuery } from "@tanstack/react-query";
import fetchJson from "../utils/fetchJson";
import apiRoutes from "../utils/apiRoutes";
import { useMemo, useState } from "react";
import buildQueryUrl from "../utils/buildQueryUrl";
import { Box, IconButton, Slider, Typography } from "@material-ui/core";
import NavigationRefresh from '@material-ui/icons/Refresh';
import moment from "moment";
import { fillDateGaps } from "./data-utils";

interface ClidRec {
    clid: string,
    gender: "male" | "female",
    count: number,
}

interface ISourceRecord {
    _id: string | Date,
    data: ClidRec[]
}

// const IDataRecord{
//     _id:Date,
// }

interface Props {
    timezone: string,

}

const colors: string[] = ['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB', '#EB9694', '#FAD0C3', '#FEF3BD', '#C1E1C5', '#BEDADC', '#C4DEF6', '#BED3F3', '#D4C4FB'];

export const NewByGenderClid = ({ timezone }: Props) => {
    const [days, setDays] = useState(30);
    const { data = [], refetch } = useQuery<ISourceRecord[]>(
        ['new-by-gender-clid', days, timezone],
        {
            queryFn: () =>
                fetchJson(buildQueryUrl(apiRoutes().dashboard.newByGenderClid, { days, timezone }))
                    .then(resp => {
                        return resp.json
                    }),
            staleTime: Infinity,
            refetchOnWindowFocus: false
        }
    );



    const dataFilled = fillDateGaps(data, days);
    // console.log({data, data1 })
    const clids = useMemo(() => Object.keys(dataFilled.reduce((acc, { data = [] }) => ({ ...acc, ...data.reduce((icc, { clid }) => ({ ...icc, [clid]: 1 }), {}) }), {})),
        [dataFilled])
    console.log({ clids });

    const reorg = (items: ISourceRecord[], selectGender) => {
        const re = items.map(({ _id, data = [] }) =>
            data
                .filter(({ gender }) => gender == selectGender)
                .reduce((acc, { clid, count, gender }) => ({ ...acc, [clid]: count }), { _id }))
        return re;
    }

    const maleData = reorg(dataFilled, "male")
    const femaleData = reorg(dataFilled, "female")
    return (
        <Box>
            <IconButton onClick={() => refetch()} aria-label="refresh" color="primary"><NavigationRefresh /></IconButton>
            <Typography variant="h6" align="center">Registrations By AdSource</Typography>
            <Typography variant="subtitle2">Male</Typography>
            <ResponsiveContainer width="100%" height={140}>
                <LineChart data={maleData} margin={{ top: 10, right: 10, left: -20, bottom: 0 }} syncId={"new-by-gender-clid"}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="_id"
                        tick={{ fontSize: 12 }}
                        tickFormatter={(value) => (!value || value === "auto") ? value : moment(value).format('D MMM')}
                    />
                    <YAxis tick={{ fontSize: 12 }} />
                    <Tooltip
                        labelFormatter={(value: any) => moment(value).format('D MMM')}
                    />
                    {/* <Legend /> */}
                    {clids.map((key, index) =>
                        <Line key={key} dataKey={key} stroke={key == "null" ? "#aaa" : colors[index]} />)
                    }
                </LineChart>
            </ResponsiveContainer>
            <Typography variant="subtitle2">Female</Typography>
            <ResponsiveContainer width="100%" height={140 + 20}>
                <LineChart data={femaleData} margin={{ top: 0, right: 10, left: -20, bottom: 0 }} syncId={"new-by-gender-clid"}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="_id"
                        tick={{ fontSize: 12 }}
                        tickFormatter={(value) => (!value || value === "auto") ? value : moment(value).format('D MMM')}
                    />
                    <YAxis tick={{ fontSize: 12 }} />
                    <Tooltip
                        labelFormatter={(value: any) => moment(value).format('D MMM')}
                    />
                    <Legend />
                    {clids.map((key, index) =>
                        <Line key={key} dataKey={key} stroke={key == "null" ? "#aaa" : colors[index]} />)
                    }
                </LineChart>
            </ResponsiveContainer>
            <Box px={"15%"}>
                <Slider
                    value={days}
                    onChange={(e, newValue) => setDays(newValue as number)}
                    marks={[{ value: 30, label: "30" }, { value: 3 * 30, label: "90" }, { value: 6 * 30, label: "180" }, { value: 365, label: "365" }]}
                    // valueLabelDisplay="on"
                    step={null}
                    min={20} max={380}
                />
            </Box>
        </Box>)
}