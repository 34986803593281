
import { Create, SimpleForm, TextInput, CreateProps, BooleanInput, NumberInput, SelectInput, Edit, ImageField, ImageInput, FileField, TextField } from "react-admin";
import ImageInputSafe from "../components/ImageInputSafe";
import PriceInput from "./PriceInput";
import PricingInput from "./PricingInput";


function findDuplicate(array: []) {
    const keySet = new Set();
    for (var i in array) {
        const val = array[i];
        if (keySet.has(val)) {
            return i;
        }
        keySet.add(val)
    }
    return 0;
}

const validateForm = (record: any) => {
    const errors: any = {}

    // const currencyDuplicate = findDuplicate(record.pricing.map((p: any) => p.currency))
    // if (currencyDuplicate) {
    //     //errors.pricing = new Array(record.pricing.length);
    //     //errors.pricing[currencyDuplicate] = { currency: ["Duplicated currency"] }
    //     errors.pricing = ["Duplicated currency"]
    //     // errors.count = ["Errrrrrrrr"]
    // }
    // console.log(errors, record);
    return errors;
}

const CreditProductForm = (props: any) =>
    <SimpleForm {...props} validate={validateForm}  >
        <TextInput source="title" required />
        <TextInput source="description" />
        <BooleanInput source="recommended" />
        <BooleanInput source="active" />
        <SelectInput source="target.role" allowEmpty choices={[
            { id: "papik", name: "papik" },
            { id: "baby", name: "baby" }
        ]} />
        <SelectInput source="target.gender" allowEmpty choices={[
            { id: "male", name: "male" },
            { id: "female", name: "female" }
        ]} />
        <NumberInput source="target.age.min" />
        <NumberInput source="target.age.max" />
        <ImageInputSafe source="imageUrl" accept="image/*" multiple={false} />

        <NumberInput source="count" />
        <PricingInput source="pricing" />
        
        <NumberInput source="displayOrder" />
    </SimpleForm>


const CreditProductCreate = ({ ...props }: CreateProps) => {

    return (
        <Create {...props}>
            <CreditProductForm />
        </Create>
    );

}


const CreditProductEdit = ({ ...props }: CreateProps) => {

    return (
        <Edit {...props}>
            <CreditProductForm />
        </Edit>
    );

}


export { CreditProductCreate, CreditProductEdit };