import {Box, IconButton, Typography} from "@material-ui/core";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import apiRoutes from "../utils/apiRoutes";
import buildQueryUrl from "../utils/buildQueryUrl";
import fetchJson from "../utils/fetchJson";
import NavigationRefresh from "@material-ui/icons/Refresh";
import {useQuery} from "@tanstack/react-query";

interface Record {
    _id: string,
    count: number
}

interface Props {
    timezone: string,
    days?: number
}

export default ({ timezone, days = 30 }: Props) => {

    const {data,refetch} = useQuery<Record[]>(
        ['active-day-by-day', days, timezone],
        () => fetchJson(buildQueryUrl(apiRoutes().dashboard.activeByDate, {days, timezone}))
            .then(resp => {
                return resp.json}),
        {
            staleTime: Infinity,
            refetchOnWindowFocus: false
        }
    );

    return (
        <Box>
            <IconButton onClick={() => refetch()} aria-label="refresh" color="primary"><NavigationRefresh/></IconButton>
            <Typography variant="h6" align="center">Active Unique</Typography>
            <ResponsiveContainer width="100%" height={220}>
                <LineChart data={data} margin={{ top: 20, right: 10, left: -20, bottom: 0 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="_id"
                        tick={{ fontSize: 12 }}
                        tickFormatter={(value) => (!value || value === "auto") ? value : moment(value).format('D MMM')}
                    />
                    <YAxis tick={{ fontSize: 12 }} />
                    <Tooltip
                        labelFormatter={(value: any) => moment(value).format('D MMM')}
                    />
                    <Legend />
                    <Line dataKey="count" />
                </LineChart>
            </ResponsiveContainer>
        </Box>
    )



}