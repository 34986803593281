import React from "react";
import { Filter, ReferenceInput, SelectInput } from "ra-ui-materialui";
import { AutocompleteInput } from "react-admin";


export default ( props: any ) => (
    <Filter { ...props }>
        <ReferenceInput label="User" source="user" reference="user" allowEmpty alwaysOn
                        filterToQuery={ txt => ( { profile: { nickname: txt } } ) }>
            <AutocompleteInput optionText="profile.nickname"/>
        </ReferenceInput>
        <SelectInput source="type" choices={ [
            { id: 'bonus', name: 'bonus' },
            { id: 'purchase', name: 'purchase' },
            { id: 'receive', name: 'receive' },
            { id: 'sent', name: 'sent' },
            { id: 'spend', name: 'spend' },
            { id: 'debit', name: 'debit' },
            { id: 'reduce', name: '*reduce' },
        ] }/>
        <SelectInput source="action" choices={ [
            { id: 'moderate', name: 'moderate' },
            { id: 'p2p-credit-transfer', name: 'p2p-credit-transfer' },
            { id: 'product-credits', name: 'product-credits' },
            { id: 'service-boostProfile', name: 'service-boostProfile' },
            { id: 'service-firstMessage', name: 'service-firstMessage' },
            { id: 'service-firstReply', name: 'service-firstReply' },
            { id: 'service-sendLike', name: 'service-sendLike' },
            { id: 'service-revealLike', name: 'service-revealLike' },
            { id: 'user-claim-today', name: 'user-claim-today' },
            { id: 'user-complete-profile', name: 'user-complete-profile' },
            { id: 'user-register', name: 'user-register' },
            { id: 'user-update-profile', name: 'user-update-profile' },
            { id: 'reduce-reward', name: '*reduce-reward' },
            { id: 'debit-rewards', name: 'debit-rewards' }
        ] }/>
    </Filter>
)