import { AutocompleteInput, BooleanField, BooleanInput, Datagrid, DateField, Filter, FilterProps, List, ListProps, Pagination, PaginationProps, ReferenceField, ReferenceInput, TextField } from "react-admin";
import { DateTimeField } from "../User/UserList/UserFields";


const rowStyle = (record: any) => {
    return {
        backgroundColor: record?.deleted && "#e6c8c85b",
    }
}

export const UserLikeList = (props: ListProps) => (
    <List {...props}
        filters={<UserLikesFilter />}
        sort={{ field: 'updatedAt', order: 'DESC' }}
        perPage={100}
        pagination={<UserLikeListPagination />}
    >
        <Datagrid rowClick="edit" rowStyle={rowStyle}>
            {/* <TextField source="id" /> */}
            <ReferenceField label="Actor(Liker)" source="actor" reference="user">
                <TextField source="profile.nickname" />
            </ReferenceField>
            {/* <TextField source="actor" /> */}
            <ReferenceField label="Subject(Liked)" source="subject" reference="user">
                <TextField source="profile.nickname" />
            </ReferenceField>
            <DateTimeField source="createdAt" />
            <DateTimeField source="updatedAt" />
            <BooleanField source="deleted" />
            <DateField source="deletedAt" />
        </Datagrid>
    </List>
);

const UserLikeListPagination = (props: PaginationProps) => <Pagination rowsPerPageOptions={[50, 100, 200]} {...props} />;
const UserLikesFilter = (props: any) => (
    <Filter {...props}>
        <ReferenceInput label="Actor(Liker)" source="actor" reference="user" allowEmpty alwaysOn filterToQuery={txt => ({ profile: { nickname: txt } })}>
            <AutocompleteInput optionText="profile.nickname" />
        </ReferenceInput>
        <ReferenceInput label="Subject(Liked)" source="subject" reference="user" allowEmpty alwaysOn filterToQuery={txt => ({ profile: { nickname: txt } })}>
            <AutocompleteInput optionText="profile.nickname" />
        </ReferenceInput>
        <BooleanInput label="Deleted" source="withDeleted" alwaysOn />
    </Filter>
)