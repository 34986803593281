import { TextFieldProps, useListContext, useRefresh } from "react-admin";
import { Tooltip, Typography, IconButton, Menu, MenuItem, MenuList, ClickAwayListener, Popover, Button, Box, TextField, Select } from '@material-ui/core'
import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { useQuery } from "@tanstack/react-query";
import { LocationInfo } from "./types";
import { getLocationInoByIp } from "./ip-api";
import { useCallback, useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import { fetchJsonInternal } from "../../../utils/authProvider2";
import apiRoutes from "../../../utils/apiRoutes";
import useBlacklistIp, { useBlIpTTL } from "../../useBlacklistIp";
import moment from "moment";
// import { getLocationInoByIp } from "./ipapi.co";
// import { getLocationInoByIp } from "./ipgeolocation";

const timeouts = [
    { value: 30, text: "30 sec" },
    { value: 2 * 60 * 60, text: "2 hours" },
    { value: 6 * 60 * 60, text: "6 hours" },
    { value: 12 * 60 * 60, text: "12 hours" },
    { value: 24 * 60 * 60, text: "24 hours" },
    { value: 2 * 24 * 60 * 60, text: "2 days" },
    { value: 5 * 24 * 60 * 60, text: "5 days" },

]

const styles = (theme: Theme) => createStyles({

    matchedIp: {
        backgroundColor: "#FFFF00"
    }
})
const useStyles = makeStyles(styles)


const IpField = (props: TextFieldProps) => {
    const classes = useStyles();
    const listContext = useListContext()
    const { record, source } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const ip = get(record, source)//record?.[source!];

    const { set: blackListIp } = useBlacklistIp()

    const matched = useMemo(() => record && listContext.ids.filter(id => {
        const otherRecord = listContext.data[id];
        return id != record.id && get(otherRecord, source) == ip;
    }), [, listContext, record, source, ip])





    const { data: location } = useQuery(['ip-loc', ip], () => getLocationInoByIp(ip), {
        enabled: !!(ip && ip !== "::1"),
        staleTime: Infinity,
        cacheTime: Infinity,
    })


    const renderLocation = (location: LocationInfo) => {
        if (location) {
            const norm = `${location.latitude}, ${location.longitude}`;
            const href = `https://www.google.com/maps/search/?api=1&query=${norm}`;

            return (
                <div>
                    <Typography variant="caption" component="div">
                        <Flag location={location} /> {location.cityName}
                    </Typography>
                    <Typography variant="caption" >

                        <a href={href} target="_blank" rel="noreferrer">
                            {norm}
                        </a>
                    </Typography>
                </div>

            )
        } else return null;
    }

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);

    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    const refresh = useRefresh()
    const [timeout, setTimeOut] = useState(2 * 60 * 60)
    const handleBlacklist = useCallback(async () => {
        handleClose();
        const status = await blackListIp(ip, timeout);

        refresh()
        console.log("blacklist", { status })
    }, [ip, timeout])

    const { ttl, isLoading } = useBlIpTTL(ip)

    if (ip) {

        return (
            <div onClick={(e) => e.stopPropagation()}>
                <Box onClick={handleClick} border={ttl ? "black 2px solid" : ""} px={0.5}>
                    <Typography variant="caption" component="div" className={matched?.length > 0 && classes.matchedIp}>
                        {ip}
                    </Typography>
                    {ttl && <Typography variant="caption" color="error">{moment().add(ttl, "seconds").fromNow()}</Typography>}
                    {renderLocation(location!)}
                </Box>
                <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
                    <Box display="flex" flexDirection="row" alignItems={"center"} p={1}>
                        <Box px={2} >
                            <Typography component={"span"} variant="subtitle2">Blacklist:  </Typography>
                            <Typography component={"span"} variant="body2" style={{ fontWeight: "bold" }}>{ip}</Typography>
                        </Box>
                        <Select label="time" value={timeout} onChange={(event) => setTimeOut(+(event.target.value))}>
                            {timeouts.map(({ value, text }) => <MenuItem key={value} value={value}>{text}</MenuItem>)}
                        </Select>
                        <Button variant="contained" color="primary" onClick={handleBlacklist}>Ok</Button>
                    </Box>


                </Popover>

            </div>
        )
    } else return null
}



interface FlagProps {
    location: LocationInfo
}
const Flag = ({ location }: FlagProps) => {
    return (
        <Tooltip title={location.countryName}>
            <img height={"14px"} src={location.flagUrl} alt={"location country flag icon"} />
        </Tooltip>
    )
}

export default IpField;