import { AutocompleteInput, BooleanField, BooleanInput, Datagrid, DateField, Filter, List, Pagination, PaginationProps, ReferenceField, ReferenceInput, TextField } from "react-admin";
import { DateTimeField } from "../User/UserList/UserFields";



const rowStyle = (record: any) => {
    return {
        backgroundColor: record?.deleted && "#e6c8c85b",
    }
}
export const UserLikeMatchList = props => (
    <List {...props}
        filters={<UserLikesFilter />}
        // filter={{ withDeleted: true }}
        sort={{ field: 'createdAt', order: 'DESC' }}
        perPage={100}
        pagination={<UserLikeMatchListPagination />}
    >
        <Datagrid rowClick="edit" rowStyle={rowStyle}>
            {/* <TextField source="id" /> */}
            {/* <TextField source="users" /> */}
            <ReferenceField label="User 1" source="users[0]" reference="user">
                <TextField source="profile.nickname" />
            </ReferenceField>
            <ReferenceField label="User 2" source="users[1]" reference="user">
                <TextField source="profile.nickname" />
            </ReferenceField>
            <DateTimeField source="createdAt" />
            <DateTimeField source="updatedAt" />
            <BooleanField source="deleted" />
            <DateField source="deletedAt" />
        </Datagrid>
    </List>
);

const UserLikeMatchListPagination = (props: PaginationProps) => <Pagination rowsPerPageOptions={[50, 100, 200]} {...props} />;
const UserLikesFilter = (props: any) => (
    <Filter {...props}>
        <ReferenceInput label="user" source="users" reference="user" allowEmpty alwaysOn filterToQuery={txt => ({ profile: { nickname: txt } })}>
            <AutocompleteInput optionText="profile.nickname" />
        </ReferenceInput>

        <BooleanInput label="Deleted" source="withDeleted" alwaysOn />
    </Filter>
)