import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Create, CreateProps, Edit, EditProps, SimpleForm, TextInput } from "react-admin"
import JsonInput from './JsonInput';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        input: {
            width: "512px"
        },

    }))


const MailBatchForm = (props: any) => {
    const classes = useStyles();
    return (
        <SimpleForm {...props} >
            {/* <TextInput source="id" disabled className={classes.input} /> */}
            <TextInput source="name" required className={classes.input} />
            <TextInput source="description" className={classes.input} />
            <TextInput source="template" className={classes.input} />
            <JsonInput source="query" className={classes.input} />

        </SimpleForm>
    )
}

const MailBatchEdit = (props: EditProps) => {

    return (
        <Edit {...props}>
            <MailBatchForm />
        </Edit>

    )
}
const MailBatchCreate = (props: CreateProps) => {
    return (
        <Create {...props}>
            <MailBatchForm initialValues={{ query: "{}", template: "test" }} redirect="list" />
        </Create>

    )
}

export { MailBatchEdit, MailBatchCreate };