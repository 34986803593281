import _ from 'lodash';
import { useMemo } from 'react';
import { InputProps, SelectInput, useQueryWithStore, useRecordContext } from "react-admin"

interface Props extends InputProps {
    pre?: { id: any, name: string }[]
}
export default function ProfileSelectInput({ pre = [], ...props }: Props) {
    const { data, loading, error } = useQueryWithStore({
        type: 'getOne',
        resource: 'locale',
        payload: { id: "en" }
    });
    const record = useRecordContext(props);
    const { source, localePath, ...other } = props;
    const field = localePath || source.split('.')[1];

    const choices = useMemo(() => {
        if (data) {
            if (record?.profile?.role) {
                const role = record.profile.role;
                const section = data[field];
                const subsection = section.default || section[role];
                return subsection?.options.map(({ _id, text }: any) => ({ id: _id, name: text })) || [];
            } else {
                const section = data[field];
                const mergeSectionOptions = (section$) => {
                    const ids = _.chain(section$).values()
                        .filter(({ options }) => options?.length > 0)
                        .flatMap(({ options }) => options.map(({ _id }) => _id))
                        .uniq()
                        .map(id => ({ id, name: `${id}` }))
                        .value()
                    return ids;
                }
                const defaultSectionOptions = (section$) => {
                    const options = section$.default?.options?.map(({ _id, text }: any) => ({ id: _id, name: text }))
                    return options
                }
                const options = defaultSectionOptions(section) || mergeSectionOptions(section)
                return options
            }
        } else {
            return [];
        }
    }, [data, record, field])

    return (
        <SelectInput
            disabled={!choices}
            // error={choices ? false : "Need role+gender first"}
            variant="outlined"
            choices={[...pre, ...choices]}
            source={source}
            {...other}
            optionValue={"id"}
            optionText="name"
        />
    )
}