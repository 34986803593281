import {
    ArrayField,
    BooleanField,
    ChipField,
    Datagrid,
    ImageField,
    List,
    NumberField,
    Pagination,
    SingleFieldList,
    TextField
} from "react-admin";
import ImageFieldExt from "../components/ImageFieldExt";
import ProductFilter from './ProductFilter';
import { Chip } from "@material-ui/core";


const ProductPagination = (props: any) => <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />;

export const ProductList = (props: any) => (
    <List {...props}
        perPage={10}
        pagination={<ProductPagination />}
        filters={<ProductFilter />}
        sort={{ field: 'displayOrder', order: 'ASC' }}>
        <Datagrid rowClick="edit">
            {/* <TextField source="id" /> */}
            <TextField source="title" />
            <ImageFieldExt source="imageUrl" label="Image" />
            <BooleanField source="active" />
            <TextField source="type" />
            <BooleanField source="recommended" />

            <NumberField source="count" />
            <NumberField source="duration" />
            <TextField source="units" />
            <BooleanField source="autoRenewal" />
            <NumberField source="displayOrder" />
            <TextField source="target.role" />
            <TextField source="target.gender" />
            <NumberField source="target.age.min" />
            <NumberField source="target.age.max" />

            <ArrayField source="pricing">
                <Datagrid>
                    <TextField source="currency" />
                    <CountriesListField />
                   
                    <NumberField source="price" />
                    <NumberField source="priceOld" />
                </Datagrid>
            </ArrayField>


            {/* <ArrayField source="price">
                <Datagrid>
                    <NumberField source="value" />
                    <TextField source="currency" />
                </Datagrid>
            </ArrayField>


            <ArrayField source="priceOld" >
                <Datagrid>
                    <NumberField source="value" />
                    <TextField source="currency" />
                </Datagrid>
            </ArrayField> */}

        </Datagrid>
    </List>
);

const CountriesListField = (props) => {
    return (
        <div>
            {props.record.countries?.map((country) => <Chip size="small" label={country} />)}
        </div>
    )
}