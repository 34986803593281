import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';



const param = {
    "url": "https://api.freecurrencyapi.com/v1/latest",
    "apikey": "rI2tOGCXUNNHtI2RSG4HrHhYhrsvK45LHe8RBj9N",
    "base_currency": "USD",
    "currencies": "USD,EUR,GBP"
};

const knownCurrencies = ["USD", "EUR", "GBP"];
param.currencies = knownCurrencies.join();


export default function useCurrencyRates() {
    const { data: rates, isFetched } = useQuery<Record<string, number>>({
        queryKey: ["currency-rates"],
        queryFn: () => fetch(`${param.url}?apikey=${param.apikey}&base_currency=${param.base_currency}&currencies=${param.currencies}`)
            .then(response => response.json())
            .then(d => d.data),
        select: (({ USD, ...rest }) => ({ USD, USDT: USD, BUSD: USD, ...rest }))
    })

    console.log(rates)
    // 0.95 EUR   => 1 USD
    // 10 EUR     => ? USD
    //
    const valueIn = useCallback((srcVal, srcCurrency, destCurrency) => {
        return isFetched ? srcVal / rates[srcCurrency] * rates[destCurrency] : undefined;
    }, [isFetched, rates])

    return { valueIn, rates, isFetched };
}