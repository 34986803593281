import React from "react";
import { BooleanField, FieldProps, NumberField, Pagination, PaginationProps, Show, SimpleShowLayout, TextField } from "react-admin";
import { Datagrid, DateField, List, ListProps, ReferenceField, ReferenceManyField, SingleFieldList } from "ra-ui-materialui";

import UserFilter from "./UserFilter";
import { EllipsesTextField, OnlineField, LocationField, DateTimeField, MultiSourceField } from "./UserFields";
import UserListActionButtons from "./UserListActionButtons";
import StatusField from './StatusField'
import IpField from "./IpField";
import photoUrl from "../../utils/photoUrl";
import ImageFieldExt from "../../components/ImageFieldExt";
import ShowMessagesButton from "../UserEdit/ShowMessagesButton";
import get from 'lodash/get';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import JsonFormatter from "react-json-formatter";
import { MetadataField } from "./MetadataField";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    rowCell: {
        padding: "4px 8px 4px 8px",
    },
    headerCell: {
        padding: "4px 8px 4px 8px",
    },
    checkbox: {
        padding: "4px 8px 4px 8px",
    },
}));

const UserPagination = (props: PaginationProps) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const rowStyle = (record: any) => {
    return {
        textDecoration: record?.deleted && "line-through",
    }
}



export const UserList = ({ permissions, ...props }: ListProps) => {
    const classes = useStyles();
    return (
        <List {...props} permissions={permissions}
            perPage={25}
            pagination={<UserPagination />}
            sort={{ field: 'createdAt', order: 'DESC' }}
            filters={<UserFilter />}
            bulkActionButtons={<UserListActionButtons />}

        >
            <Datagrid rowClick="edit" rowStyle={rowStyle}
                classes={{ rowCell: classes.rowCell, headerCell: classes.headerCell, checkbox: classes.checkbox }}
                expand={<ExpandDetails />}
            >

                <ReferenceField source="id" reference="user">
                    <TextField source="email" />
                </ReferenceField>
                <ReferenceManyField label="Photo" perPage={1}
                    reference="photo" target="user" filter={{ moderation: "accepted" }} sort={{ field: "isMain", order: "DESC" }}>
                    <SingleFieldList linkType={false}>
                        <ImageFieldExt source="id" label="Image" getSrc={(photo) => photo && photoUrl(photo, 'tiny')} />
                    </SingleFieldList>
                </ReferenceManyField >
                <EllipsesTextField source="profile.nickname" label="Nickname" />
                <TextField source="profile.age" label="Age" />
                <MetadataField label="AdSource" />
                <StatusField label="Status" />
                <NumberField source="wallet.credits" label="Credits" variant="body1" color="error" style={{ fontWeight: "bold" }} />
                <NumberField source="wallet.rewards" label="Rewards" variant="body1" style={{ color: "green", fontWeight: "bold" }} />
                <NumberField source="stats.subjectCnt" label="S" variant="body1" style={{ color: "#ff40e6", fontWeight: "bold" }} />
                <NumberField source="stats.actorCnt" label="A" variant="body1" style={{ color: "#1cbb21", fontWeight: "bold" }} />
                
                {/* <MultiSourceField sources={["stats.gotLikes", "stats.liked"]} separator="/"  label="Got / Liked" /> */}
                <ShowMessagesButton label="Messages" />

                <OnlineField source="lastSeen" label="Online" />

                <DateTimeField source="createdAt" split />

                <ReferenceField label="City" source="profile.city" reference="city">
                    <TextField source="name" />
                </ReferenceField>
                <LocationField source="location" />
                <IpField source="clientIp" />
                {/* <BooleanField source="fake" /> */}
                {/* <BooleanField source="state.notifications.emailBounce" label="Email Bounce" /> */}
            </Datagrid>
        </List>
    )
};

const ExpandDetails = (props) => {
    return (<Show {...props} title="" hasEdit={false}>
        <SimpleShowLayout>
            <JsonField source="metadata" />
        </SimpleShowLayout>
    </Show>)
}

const JsonField = (props: FieldProps) => {
    const { record, source, label } = props;
    const recordValue = get(record, source);
    if (recordValue) {
        const text = JSON.stringify(recordValue);
        const jsonStyle = {
            propertyStyle: { color: 'red' },
            stringStyle: { color: 'green' },
            numberStyle: { color: 'darkorange' }
        };

        return <Typography>
            {source}:
            <JsonFormatter json={text} tabWith={4} jsonStyle={jsonStyle} />
        </Typography>
    } else {
        return null
    };


}



