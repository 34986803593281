import { BooleanInput, Create, Edit, SimpleForm, TextInput } from "react-admin";




export default function EmailDomainCreate(props) {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="id" label={"Domain"} />
                <BooleanInput source="isDisposable" defaultValue={true} />
            </SimpleForm>
        </Create>
    )
}