import React, { useState } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles, Theme, withStyles, createStyles } from '@material-ui/core/styles';


interface Option<T> {
    value: T;
    label: string;
}
interface Props<T = number> {
    value: T;
    options: Option<T>[];
    onChange: (value: T) => void;
}



const StyledToggleButtonGroup = withStyles((theme: Theme) => ({
    grouped: {
        margin: theme.spacing(0.5),
        padding: '2px',
        minWidth: '40px',
        textTransform: 'none',
        background: '#f8f8ff',
        border: 'none',
        '&:not(:first-child)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-child': {
            borderRadius: theme.shape.borderRadius,
        },
    },
    root: {
        '& .Mui-selected': {
            background: '#46d06d',
            color: 'white',
        },
    }

}), { name: "StyledToggleButtonGroup" })(ToggleButtonGroup);

// const useStyles = makeStyles((theme: Theme) =>  // WAY-2
//     createStyles({
//         selectedButton: {
//             backgroundColor: '#46d06d !important',
//             color: 'white !important',
//         }
//     }),
// );

export default function ToggleButtonsMultiple(props: Props) {
    const { value, options, onChange } = props;
    const handleOnChange = (value: number) => {
        if (typeof value === "number") {
            onChange(value)
        }
    }

    // const classes = useStyles();  // WAY-2

    return (
        <StyledToggleButtonGroup size="small" color="primary"
            value={value} onChange={(e: any, value: number) => handleOnChange(value)}
            // aria-label="totals-timeframe"
            exclusive >

            {options.map((frame) =>

                <ToggleButton key={frame.value} value={frame.value} aria-label={frame.label}
                // classes={{ selected: classes.selectedButton }}  // WAY-2
                >
                    {frame.label}
                </ToggleButton>
            )}

        </StyledToggleButtonGroup>
    );
}
