



interface Request<I extends string | number | symbol, D> {
    id: I
    resolve: (value: D) => void;
    reject: (reason?: any) => void;
}


class DeferredAggregate<I extends string | number | symbol, D>{

    private timer: any;
    private burst: Request<I, D>[];
    constructor(private timeout: number, private executeBurst: (ids: I[]) => Promise<Record<I, D>>) {
        this.timer = null;
        // this.items = [];
        this.burst = [];
    }

    requestOne = (id: I): Promise<D> => {
        return new Promise<D>((resolve, reject) => {

            this.burst.push({ id, resolve, reject });

            if (!this.timer) {
                this.timer = setTimeout(() => {
                    const requests = this.burst;
                    this.timer = null;
                    this.burst = [];
                    //execute requests
                    if (requests.length) {
                        this.executeBurst(requests.map(r => r.id))
                            .then(result => {
                                requests.forEach(request => {
                                    const res = result[request.id];
                                    if (res !== undefined) {
                                        request.resolve(res)
                                    } else {
                                        request.reject(new Error(`${id.toString()}  Not found`))
                                    }
                                })
                            })
                            .catch((reason) => {
                                requests.forEach(request => {
                                    request.reject(reason)
                                })
                            })
                    }


                }, this.timeout);
            }
        })

    }
}

export default DeferredAggregate;




