import {
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup, IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import {useMemo, useState} from "react";
import {useAuthenticated} from "react-admin";
import apiRoutes from "../utils/apiRoutes";
import buildQueryUrl from "../utils/buildQueryUrl";
import fetchJson from "../utils/fetchJson";
import ToggleButtonsMultiple from './ToggleButtonsMultiple';
import {useQuery} from "@tanstack/react-query";
import moment from "moment-timezone";
import NavigationRefresh from "@material-ui/icons/Refresh";

const useCheckboxStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            fontSize: "14px"
        }
    }));

const timeFrameOptions = [
    {value: 0, label: "off"},
    {value: 5, label: "5 min"},
    {value: 15, label: "15 min"},
    {value: 30, label: "30 min"},
    {value: 60, label: "1 hour"},
];

interface Cell {
    gender: string,
    sponsor: boolean,
    count: number
};

const genders = ["male", "female"];
const placeholder: Cell[] = genders.flatMap(gender => ([true, false].map(sponsor => ({gender, sponsor, count: 0}))));

interface Props {
    timezone: string,
    days?: number
}

const useStyles = makeStyles({
    greyRow: {
        backgroundColor: "rgba(0, 0, 0, 0.10)",
    },
});

const loadData = async (query: any, timezone: string): Promise<Cell[]> => {
    const {json} = await fetchJson(buildQueryUrl(apiRoutes().dashboard.totals, {...query, timezone}))
    return json.map(({_id: {gender, sponsor}, count}: any) => ({gender, sponsor, count}));
}

export default ({timezone}: Props) => {
    useAuthenticated();
    const classes = useStyles();
    const [query, setQuery] = useState({
        exclBlocked: true,
        activeOnly: false,
        verifiedOnly: false,
        lastSeenMin: 0,
        incDeleted: false
    });

    const {refetch, data} = useQuery<Cell[]>(
        ['totals', timezone, query],
        () => loadData(query, timezone),
        {
            staleTime: Infinity,
            refetchOnWindowFocus: false,
            enabled: true
        }
    );

    const tabular = useMemo(() => {
        const row: { [key: string]: (number | undefined)[] } = {
            male:[0,0,0],
            female:[0,0,0],
            total:[0,0,0],
            unknowns:[undefined, undefined, 0]
        };//Record<string, number[]>
        if (data) {
            const total: number[] = [0, 0, 0];
            const unknowns = data.filter(c => !c.gender || typeof c.sponsor !== "boolean").reduce((acc, c) => c.count + acc, 0);
            const sortedData = [...data].filter(c => c.gender && typeof c.sponsor === "boolean").sort((a: any, b: any) => {
                return -a.gender.localeCompare(b.gender);
            });
            for (const cell of sortedData) {
                const r = row[cell.gender] = row[cell.gender] || [0, 0, 0];
                const spInx = cell.sponsor ? 1 : 0
                r[spInx] = cell.count;
                total[spInx] = (total[spInx] || 0) + cell.count;
                r[2] = (r[2] || 0) + cell.count
            }
            //1 sponsor, 0 not sponsor
            total[2] = total[0] + total[1]
            row["total"] = total;
            row["unknowns"] = [undefined, undefined, unknowns];

        }

        return Object.entries(row);

    }, [data])

    const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.name)
        setQuery({...query, [event.target.name]: event.target.checked});
    };
    const handleLastSeenChange = (newValue: any) => {
        setQuery({...query, lastSeenMin: newValue});
    };

    const checkBocClasses = useCheckboxStyles();

    return (
        <Box px={4}>
            <IconButton onClick={() => refetch()} aria-label="refresh" color="primary"><NavigationRefresh/></IconButton>
            <FormGroup row>
                <FormControlLabel classes={checkBocClasses}
                                  control={<Checkbox size="small" checked={query.exclBlocked} name="exclBlocked"
                                                     onChange={handleQueryChange}/>} label="Excl. Blocked"/>
                <FormControlLabel classes={checkBocClasses}
                                  control={<Checkbox size="small" checked={query.activeOnly} name="activeOnly"
                                                     onChange={handleQueryChange}/>} label="Active Only"/>
                <FormControlLabel classes={checkBocClasses}
                                  control={<Checkbox size="small" checked={query.verifiedOnly} name="verifiedOnly"
                                                     onChange={handleQueryChange}/>} label="Verified Only"/>
            </FormGroup>
            <ToggleButtonsMultiple value={query.lastSeenMin} onChange={handleLastSeenChange}
                                   options={timeFrameOptions}/>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow className={classes.greyRow}>
                            <TableCell>Sponsor{"=>"}</TableCell>
                            <TableCell align="center">No</TableCell>
                            <TableCell align="center">Yes</TableCell>
                            <TableCell align="center">Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tabular.map(([gender, row], r) =>
                            <TableRow key={`row-${r}`}>
                                <TableCell>{gender}</TableCell>
                                {row.map((col, c) =>
                                    <TableCell align="center" key={`col-${c}`}>{col}</TableCell>
                                )}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )

}


