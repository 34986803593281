import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Photo, Moderation } from "../types";

import { makeStyles, createStyles, Theme, withStyles, fade } from '@material-ui/core/styles';

interface Props {
    value: Moderation;
    onChange?: (newValue: Moderation) => void
}

const color = (theme: Theme, value: Moderation) => {
    switch (value) {
        case "accepted": return theme.palette.success;
        case "rejected": return theme.palette.error;
        default: return null;
    }
}

const styles = (theme: Theme) => createStyles({
    buttonAcceptRoot: {
        border: `3px solid ${fade(theme.palette.success.dark, 0.8)} !important`,
        color: `${theme.palette.success.dark} !important`,
       
    },
    buttonRejectRoot: {
        border: `3px solid ${fade(theme.palette.error.dark, 0.8)} !important`,
        color: `${theme.palette.error.dark} !important`,
       
    },
    buttonAcceptSelect: {
        color: `${theme.palette.getContrastText(theme.palette.success.dark)} !important`,
        backgroundColor: `${theme.palette.success.main} !important`,
    },
    buttonRejectSelect: {
        
        color: `${theme.palette.getContrastText(theme.palette.error.dark)} !important`,
        backgroundColor: `${theme.palette.error.main} !important`,
    },
    
})

const useStyles = makeStyles(styles);



export default (props: Props) => {
    const { value, onChange } = props;
    //console.log("props", value);
    const classes = useStyles(props);

    const handleChange = (event: any, newValue: Moderation) => {
        console.log("onChange", value, "=>", newValue);
        onChange?.(newValue)
    }

    return (
        <ToggleButtonGroup value={value} exclusive onChange={handleChange}>
            <ToggleButton value="accepted" classes={{ root: classes.buttonAcceptRoot, selected: classes.buttonAcceptSelect }}>
                Accepted
            </ToggleButton>
            <ToggleButton value="rejected" classes={{ root: classes.buttonRejectRoot, selected: classes.buttonRejectSelect }} >
                Rejected
            </ToggleButton>

        </ToggleButtonGroup >
    );
}