import React from "react";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { FormWithRedirect } from "ra-core";
import { BooleanInput, DateInput, Create, NumberInput, SimpleForm, TextInput, EditProps, CreateProps, DateTimeInput, SelectInput } from "ra-ui-materialui";
import { email, PasswordInput, required, Toolbar, useTranslate } from "react-admin";

export const UserCreate = (props: CreateProps) => (
    <Create {...props}>
        <UserForm redirect="list" />
    </Create>
);

const UserForm = (props: any) => {
    const translate = useTranslate();
    const [value, setValue] = React.useState(0);
    return (
        <FormWithRedirect

            {...props}

            //validate={validatePasswords}
            render={(formProps: any) => (
                <div>

                    <Card>

                        <form>
                            <Toolbar record={formProps.record} basePath={formProps.basePath}
                                undoable={true} invalid={formProps.invalid} handleSubmit={formProps.handleSubmit}
                                saving={formProps.saving} resource="user" />
                            <CardContent>
                                <Box display={{ md: 'block', lg: 'flex' }}>
                                    <Box flex={1} mr={{ md: 0, lg: '1em' }}>
                                        <Typography variant="h6" gutterBottom>Account info</Typography>
                                        {/* <TextInput source="id" disabled fullWidth /> */}
                                        <TextInput type="email" source="email" fullWidth
                                            validate={[email(), required()]} />

                                        <TextInput source="password" fullWidth
                                            validate={[required()]}
                                        />


                                        <SelectInput source='role' fullWidth choices={[
                                            { id: 'user', name: "User" },
                                            { id: 'admin', name: "Admin" },
                                        ]}
                                            //validate={[required()]}
                                            defaultValue={'user'}
                                            disabled
                                        />
                                        <Box display="flex">
                                            <BooleanInput source="verified" defaultValue={true} />

                                            <BooleanInput source="fake" defaultValue={true} />
                                        </Box>
                                        <Box display="flex">
                                            <BooleanInput source="profileValid" lable="Searchable" />
                                            <BooleanInput source="adminBlocked" label="Blocked" />
                                        </Box>
                                        <TextInput source="verification" fullWidth />
                                        <Box display="flex">
                                            <NumberInput source="loginAttempts" />
                                            <DateTimeInput source="blockExpires" />
                                        </Box>
                                        <Box display="flex">
                                            <BooleanInput source="isOnline" disabled />
                                            <DateTimeInput source="lastSeen" disabled />

                                        </Box>
                                        <Typography variant="h6" gutterBottom>Settings</Typography>
                                        <DateInput source="createdAt" fullWidth disabled />
                                        <DateInput source="updatedAt" fullWidth disabled />
                                    </Box>
                                    <Box flex={1}>
                                        <Typography variant="h6" gutterBottom>Profile info</Typography>

                                        <TextInput source="profile.nickname" fullWidth validate={[required()]} />
                                        {/* <TextInput source="profile.avatar" fullWidth disabled /> */}
                                        <SelectInput source='profile.gender' fullWidth choices={[
                                            { id: 'male', name: "Male" },
                                            { id: 'female', name: "Female" },
                                        ]}
                                            validate={[required()]}
                                        />
                                        <BooleanInput source="profile.isSponsor" defaultValue={false} />
                                        <DateInput source="profile.dob" fullWidth validate={[required()]} />
                                        <TextInput source="profile.age" fullWidth disabled />


                                        <TextInput source="profile.sexOrientation" fullWidth />
                                        <TextInput source="profile.slogan" fullWidth />
                                        <TextInput source="profile.about" fullWidth />

                                        <TextInput source="profile.city" fullWidth validate={[required()]} defaultValue={703448} />
                                        <TextInput source="profile.languages" fullWidth />
                                        <TextInput source="profile.familyStatus" fullWidth />
                                        <TextInput source="profile.education" fullWidth />
                                        <TextInput source="profile.occupation" fullWidth />
                                        <TextInput source="profile.availableTime" fullWidth />
                                        <TextInput source="profile.incomeLevel" fullWidth />
                                        <TextInput source="profile.hobbies" fullWidth />
                                        <TextInput source="profile.bdsmRelations" fullWidth />
                                        <TextInput source="profile.sponsorType" fullWidth />
                                        <TextInput source="profile.sponsorExpectations" fullWidth />
                                        <NumberInput source="profile.searchAge.min" fullWidth defaultValue={18} validate={[required()]} />
                                        <NumberInput source="profile.searchAge.max" fullWidth defaultValue={80} validate={[required()]} />

                                        <TextInput source="profile.interestedIn" fullWidth />
                                        <TextInput source="profile.purpose" fullWidth />
                                        <TextInput source="profile.smoking" fullWidth />
                                        <TextInput source="profile.narcotics" fullWidth />
                                        <TextInput source="profile.origin" fullWidth />
                                        <TextInput source="profile.religion" fullWidth />
                                        <TextInput source="profile.ethnicity" fullWidth />
                                        <TextInput source="profile.bodyType" fullWidth />

                                        <TextInput source="profile.height" fullWidth />
                                        <TextInput source="profile.weight" fullWidth />
                                        <TextInput source="profile.hair" fullWidth />
                                        <TextInput source="profile.eyes" fullWidth />
                                        <TextInput source="profile.mood" fullWidth />
                                        <TextInput source="profile.moodExpire" fullWidth />

                                    </Box>
                                </Box>




                            </CardContent>
                            <Toolbar
                                record={formProps.record}
                                basePath={formProps.basePath}
                                undoable={true}
                                invalid={formProps.invalid}
                                handleSubmit={formProps.handleSubmit}
                                saving={formProps.saving}
                                resource="user"
                            />
                        </form>
                    </Card>
                </div>
            )}
        />
    )

}


const requiredValidate = [required()];
