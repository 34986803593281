import React, {useMemo} from 'react';
import {AutocompleteArrayInput, useQueryWithStore, useRecordContext} from "react-admin"

function ProfileMultiSelectInput(props: any) {

    const { data, loading, error } = useQueryWithStore({
        type: 'getOne',
        resource: 'locale',
        payload: { id: "en" }
    });
    const record = useRecordContext(props);
    const {source, localePath, label, ...other} = props;
    const field = localePath || source.split('.')[1];

    const choices = useMemo(() => {
        if (record?.profile?.role && data) {
            const role = record.profile.role;
            const section = data[field];
            const subsection = section.default || section[role];
            if(field === 'languages' || field === 'uiLang'){
                return subsection?.options.map(({ _id, name }: any) => ({ id: _id, name: name })) || [];
            };
            return subsection?.options.map(({ _id, text }: any) => ({ id: _id, name: text })) || [];
        } else {
            return [];
        }
    }, [data, record, field])

    return (

        <AutocompleteArrayInput
            source ={source}
            choices={choices}
            optionValue="id"
            optionText="name"
            translateChoice={false}
            variant={"outlined"}
            helperText={false}
            label={label}
        />

    )
}

export default ProfileMultiSelectInput;