import { AutocompleteInput, BooleanField, Datagrid, DateField, Filter, List, Pagination, PaginationProps, TextField, TextInput } from "react-admin";

const Pagination100 = (props: PaginationProps) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;


export default function EmailDomainList(props) {
    return (
        <List {...props} perPage={25} pagination={<Pagination100 />}
            filters={<Filters />}
        >
            <Datagrid rowClick="edit">
                <TextField source="id" label={"Domain"} />
                <BooleanField source="isDisposable" />
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
            </Datagrid>
        </List>
    )

}


const Filters = (props: any) =>
    <Filter {...props}>
        <TextInput label="Domain" source="domain" alwaysOn />
        {/* <AutocompleteInput source="id"/> */}
    </Filter>

