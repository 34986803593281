import { ListProps } from "@material-ui/core"
import {Datagrid, DateField, List, NumberField, Pagination, ReferenceField, TextField} from "react-admin"
import { DateTimeField } from "../User/UserList/UserFields"

const ClaimedDayPagination = (props:any) => <Pagination rowsPerPageOptions={[ 25, 50, 100]} {...props} />;

export default (props: ListProps) => {
    return (
        <List {...props}
            perPage={25}
            pagination={<ClaimedDayPagination />}
            sort={{ field: 'createdAt', order: 'DESC' }}
        >
            <Datagrid rowClick="edit">
                {/* <TextField source="id" /> */}
                <ReferenceField label="User" source="user" reference="user">
                    <TextField source="profile.nickname" />
                </ReferenceField>
                <NumberField source="credited" />
                <DateTimeField source="timestamp" />
                <NumberField source="index" />
                <DateTimeField source="createdAt" />
                {/* <DateTimeField source="updatedAt" /> */}



                {/* <TextField source="user" /> */}
            </Datagrid>
        </List>
    )
}