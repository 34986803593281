import apiRoutes from "./apiRoutes";
import { fetchJsonInternal } from "./authProvider2";



const sendEvent = async (type: string, action: string, data: any) => {
    const resp = await fetchJsonInternal(`${apiRoutes().sendEvent}/${type}/${action}`, { method: 'POST', mode: 'cors', body: JSON.stringify(data) })
    console.log(resp)
    return resp;
}

export default sendEvent