import { Box, Typography, IconButton, Slider } from "@material-ui/core";
import NavigationRefresh from '@material-ui/icons/Refresh';
import { Bar, CartesianGrid, ComposedChart, Legend, Line, LineChart, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import moment from "moment-timezone";

import apiRoutes from "../utils/apiRoutes";
import buildQueryUrl from "../utils/buildQueryUrl";
import fetchJson from "../utils/fetchJson";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import useCurrencyRates from "../utils/useCurrencyRates";


interface Props {
    timezone: string,
    // days?: number
}

interface DatedRecord {
    date: Date,
    amount: number,
    count: number
}

interface RecordSource {
    _id: {
        date: string,
        currency: string
    },
    amount: number,
    count: number
}

export default ({ timezone = "+0000" }: Props) => {
    const { valueIn, isFetched } = useCurrencyRates()
    const [days, setDays] = useState(30);
    const templateDayArray = useMemo(() => {
        const date = moment().subtract(days - 1, "day").startOf("day");
        return Array.from({ length: days }, (_, i) => {
            const result = {
                date: moment(date).toDate(),
                amount: 0,
                count: 0
            };
            date.add(1, 'day');
            return result;
        });

    }, [days]);


    const { data: source, refetch } = useQuery<RecordSource[]>(
        ['deosits-by-day', days, timezone],
        () => fetchJson(buildQueryUrl(apiRoutes().dashboard.depositsByDay, { days, timezone }))
            .then(resp => resp.json),
        {
            staleTime: Infinity,
            refetchOnWindowFocus: false
        }
    );

    const data = useMemo(() => {
        if (source) {
            const lookup = source.reduce((acc: Record<string, DatedRecord>, { _id: { date, currency }, amount, count }: RecordSource) => {
                const key = moment(date).format("YYYY-MM-DD");
                //TODO: Now we are ignoring exchange rates , just summing up
                const amountInUsd = valueIn(amount, currency, "USD")
                return {
                    ...acc,

                    [key]: acc[key]
                        ? { amount: acc[key].amount + amountInUsd, count: acc[key].count + count, date: acc[key].date }
                        : { date: new Date(date), amount: amountInUsd, count }
                }


            }, {});
            return templateDayArray.map(template => {
                const key = moment(template.date).format("YYYY-MM-DD");
                return lookup[key] || template;
            })
        }
    }, [source])


    return (
        <Box>
            <IconButton onClick={() => refetch({ cancelRefetch: true })} aria-label="refresh" color="primary"><NavigationRefresh /></IconButton>
            <Typography variant="h6" align="center">Deposits By Days</Typography>
            <ResponsiveContainer width="100%" height={220}>
                <ComposedChart data={data}
                    margin={{ top: 20, right: -20, left: -20, bottom: 0 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date"
                        tick={{ fontSize: 12 }}
                        tickFormatter={(value) => (!value || value === "auto") ? value : moment(value).format('DD MMM')}
                    />
                    <YAxis yAxisId="left" tick={{ fontSize: 12 }} />
                    <YAxis yAxisId="right" orientation="right" tick={{ fontSize: 12 }} />
                    <Tooltip
                        labelFormatter={(value: any) => moment(value).format('D MMM')}
                        formatter={(value: number, name, item) => {
                            return name === "amount" ? value.toFixed(2) : value;
                        }}
                    />
                    <Legend />

                    <Bar yAxisId="left" dataKey="count" fill="#413ea0" fillOpacity={0.5} stroke="#413ea0" shape={<BoxedBar />} />
                    <Line yAxisId="right" type="monotone" dataKey="amount" stroke="#387908" activeDot={{ r: 8 }} />

                </ComposedChart>

            </ResponsiveContainer>
            <Box px={"15%"}>
                <Slider
                    value={days}
                    onChange={(e, newValue) => setDays(newValue as number)}
                    marks={[{ value: 30, label: "30" }, { value: 3 * 30, label: "90" }, { value: 6 * 30, label: "180" }]}
                    // valueLabelDisplay="on"
                    step={null}
                    min={20} max={200}
                />
            </Box>
        </Box>
    )
};

const BoxedBar = (props) => {
    let { fill, x, y, width, height, value } = props;
    const _height = height / value;
    return <>
        {Array(value).fill(1).map((_, v) => {
            
            const _y = y + (_height*v)
            return <Rectangle key={v} {...props} y={_y} height={_height*0.95}  />
        })}
    </>

}