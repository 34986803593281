
import { Create, SimpleForm, TextInput, CreateProps, BooleanInput, NumberInput, SelectInput, Edit, FormDataConsumer } from "react-admin";
import ImageInputSafe from "../components/ImageInputSafe";
import PriceInput from "./PriceInput";
import PricingInput from "./PricingInput";
import { Box } from "@material-ui/core";


const SubscriptionProductForm = (props: any) =>
    <SimpleForm {...props}>
        <TextInput source="title" required helperText={false} />
        <TextInput source="description" helperText={false} />
        <BooleanInput source="recommended" />
        <BooleanInput source="active" />
        <SelectInput source="target.role" allowEmpty choices={[
            { id: "papik", name: "papik" },
            { id: "baby", name: "baby" }
        ]} />
        <SelectInput source="target.gender" allowEmpty choices={[
            { id: "male", name: "male" },
            { id: "female", name: "female" }
        ]} />
        <NumberInput source="target.age.min" />
        <NumberInput source="target.age.max" />
        <ImageInputSafe source="imageUrl" accept="image/*" multiple={false} helperText={false} />
        <Box display="grid" gridTemplateColumns={"1fr 1fr"} width={"100%"} >
            <NumberInput source="duration" fullWidth />
            <SelectInput source="units" label="units" required choices={[
                { id: 'day', name: 'Days' },
                { id: 'month', name: 'Month' }
            ]} />
        </Box>

        <BooleanInput source="autoRenewal" helperText={false} />
        <FormDataConsumer>
            {({ formData, ...rest }) =>
                formData.autoRenewal && <NumberInput source="renewalCycles" step={1} {...rest} helperText={false} />
            }
        </FormDataConsumer>

        <PricingInput source="pricing" />


        <NumberInput source="displayOrder" />

    </SimpleForm>


const SubscriptionProductCreate = ({ ...props }: CreateProps) => {

    return (
        <Create {...props}>
            <SubscriptionProductForm />
        </Create>
    );

}


const SubscriptionProductEdit = ({ ...props }: CreateProps) => {

    return (
        <Edit {...props}>
            <SubscriptionProductForm />
        </Edit>
    );

}


export { SubscriptionProductCreate, SubscriptionProductEdit };