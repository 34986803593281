import React from 'react';
import {Filter, SelectInput} from 'react-admin';

const ProductFilter = (props: any) => {

    return (
        <Filter {...props}>
            <SelectInput label="Active" source="active" fullWidth allowEmpty alwaysOn choices={[
                {id: 'true', name: "Yes"},
                {id: 'false', name: "No"},
            ]}/>
        </Filter>
    );
};

export default ProductFilter;