import {
    BooleanField, DateField, NumberField, Show, SimpleShowLayout, TextField, ShowProps
    , ReferenceField, ReferenceManyField, ArrayField, Datagrid, SingleFieldList
    , Pagination
} from "react-admin";
import ChatItem from "./ChatItem";

export default (props: ShowProps) => (
    <Show {...props}>
        <SimpleShowLayout>

            <TextField source="id" />
            {/*
            <BooleanField source="deleted" />
            <NumberField source="count" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
            <TextField source="lastMessage" />
            <DateField source="lastSent" /> 
            */}
            <ReferenceManyField
                label=""
                source="id" reference="message" target="conversation"
                sort={{ field: 'id', order: 'ASC' }}
                perPage={100}
                pagination={<Pagination rowsPerPageOptions={[50, 100, 200]} />}
            >

                <Datagrid>
                    <ChatItem />

                </Datagrid>

            </ReferenceManyField>

        </SimpleShowLayout>
    </Show>
);