import { List, Datagrid, TextField, BooleanField, NumberField, ReferenceField, Pagination, ListProps, SimpleShowLayout, Show, linkToRecord } from 'react-admin';
import { DateTimeField } from '../User/UserList/UserFields';
import TransactionFilter from './TransactionFilter';


const TransactionPagination = (props: any) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const TransactionList = (props: ListProps) => (
    <List {...props}

        perPage={25}
        pagination={<TransactionPagination />}
        sort={{ field: 'updatedAt', order: 'DESC' }}
        filters={<TransactionFilter />}>
        <Datagrid rowClick="show" expand={<ExpandDetails />}>
            <DateTimeField source="createdAt" />
            <DateTimeField source="updatedAt" />
            <ReferenceField label="User" source="user" reference="user">
                <TextField source="profile.nickname" />
            </ReferenceField>
            <ReferenceField label="Product" source="product" reference="product">
                <TextField source="title" />
            </ReferenceField>
            <ReferenceField label="Product Type" source="product" reference="product" link={false}>
                <TextField source="type" />
            </ReferenceField>
            <ReferenceField label="Recurrent" source="product" reference="product" link={false}>
                <TextField source="autoRenewal" />
            </ReferenceField>
            <NumberField source='dataList.length' label="Sub" />
            <NumberField source="amount" />
            <TextField source="currency" />
            <TextField source="status" />
            <TextField source="provider" />

        </Datagrid>
    </List>
);
export default TransactionList;


const ExpandDetails = (props) => {
    return (<Show {...props} title="">
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="refId" />
            <TextField source="type" />
            <BooleanField source="isTest" />
        </SimpleShowLayout>
    </Show>)
}