import { makeStyles } from '@material-ui/core/styles';
import { stringify } from 'query-string';

import SendMailButton from "./SendMailButton";
import { ToolbarLinkButton } from "./ToolbarButton";
import { Box } from '@material-ui/core';

const useStyles = makeStyles(
    theme => ({
        toolbar: {
            backgroundColor:
                theme.palette.type === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
            marginTop: theme.spacing(2),
            boxSizing: 'border-box',
            display: 'flex',
            flexFlow: 'row wrap',
            alignContent: 'space-between',
            justifyContent: 'space-evenly',
            padding: '3px 0px',
            minWidth: '360px',
            minHeight: '36px',
            border: '1px solid rgba(0,0,0,0.12)',
        },

    }),
    { name: 'RaToolbar' }
);

export default (props: any) => {
    const classes = useStyles();
    const makeTo = (pathname: string, filterValue: any) => ({
        pathname: pathname,
        search: stringify({
            filter: JSON.stringify(filterValue)
        })
    });
    const { record } = props;
    return record && (
        <div className={classes.toolbar}>
            <SendMailButton record={props.record} />
            <ToolbarLinkButton record={record} label='messages' to={makeTo('/message', { "sender|to": record.id })} />
            <ToolbarLinkButton record={record} label='credits log' to={makeTo('/CreditsLog', { "user": record.id })} />
            <ToolbarLinkButton record={record} label='claimed Days' to={makeTo('/ClaimedDay', { "user": record.id })} />
            <ToolbarLinkButton record={record} label='Transactions' to={makeTo('/transaction', { "user": record.id })} />
            <Box>
                <ToolbarLinkButton record={record} label='report from' to={makeTo('/report', { "reporter": record.id })} />
                <ToolbarLinkButton record={record} label='report about' to={makeTo('/report', { "about": record.id })} />
            </Box>
            <Box>
                <ToolbarLinkButton record={record} label='I Liked' to={makeTo('/userlike', { "actor": record.id })} />
                <ToolbarLinkButton record={record} label='Liked Me' to={makeTo('/userlike', { "subject": record.id })} />
                <ToolbarLinkButton record={record} label='Matches' to={makeTo('/userlikematch', { "users": record.id })} />
            </Box>
        </div>
    )
}

