import { Button } from "@material-ui/core";
import apiRoutes from "../utils/apiRoutes";
import fetchJson from "../utils/fetchJson";

const batchEmails = async (batchId: string, data?: any) => {
    const resp = await fetchJson(`${apiRoutes().batchEmails}`, { method: 'POST', mode: 'cors', body: JSON.stringify({ batchId, ...data }) })
    console.log(resp)
    return resp;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ record, className }: any) => {
    const handleClick = (e: any) => {
        e.stopPropagation();
        // console.log(record);
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Are you sure ?")) {
            batchEmails(record.id)
        }
    }
    return record ? (
        <Button
            className={className}
            size='small'
            variant="outlined"
            color="primary"
            onClick={handleClick}
        >
            Send Bulk
        </Button>
    ) : null;
};