import simpleRestProvider from 'ra-data-simple-rest';
import { DataProvider } from 'react-admin';
import fetchJson from '../fetchJson';
import transformModel from './transformModel';

//const dataProvider = simpleRestProvider(apiRoutes().data, fetchJson, 'X-Total-Count');

const createDataProvider = (apiUrl: string): any => {

    const dataProvider: DataProvider = simpleRestProvider(apiUrl, fetchJson, 'X-Total-Count');
    
    const wrappedDp = {

        ...dataProvider,
        update: async (resource: string, params: any) => {
            const transform = transformModel[resource];
            if (transform) {
                params = await transform(params)
            }

            return dataProvider.update(resource, params);
        },
        create: async (resource: string, params: any) => {
            const transform = transformModel[resource];
            if (transform) {
                params = await transform(params)
            }

            return dataProvider.create(resource, params);
        },
    }

    return wrappedDp;
}

export { createDataProvider };


