import { AutocompleteInput, Filter, ReferenceInput } from "ra-ui-materialui";


export default (props: any) =>
    <Filter {...props}>
        <ReferenceInput label="Reporter" source="reporter" reference="user" allowEmpty alwaysOn filterToQuery={txt => ({ profile: { nickname: txt } })}>
            <AutocompleteInput optionText="profile.nickname" />
        </ReferenceInput>
        <ReferenceInput label="About" source="about" reference="user" allowEmpty alwaysOn filterToQuery={txt => ({ profile: { nickname: txt } })}>
            <AutocompleteInput optionText="profile.nickname" />
        </ReferenceInput>
    </Filter>