import { useNotify, useRefresh, useUnselectAll, useUpdateMany } from "ra-core";
import { BulkDeleteButton, Button } from "react-admin"
import BlockIcon from '@material-ui/icons/Block';
// /import SendMailButton from "../SendMailButton";


const UserListActionButtons = (props: any) =>
    <>
        <AdminBlockButton {...props} />
        <BulkDeleteButton {...props} />
        {/* <SendMailButton {...props} /> */}
    </>


const AdminBlockButton = ({ selectedIds }: any) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [updateMany, { loading }] = useUpdateMany(
        'user',
        selectedIds,
        { adminBlocked: true },
        {
            onSuccess: () => {
                refresh();
                notify('user updated');
                unselectAll('user');
            },
            onFailure: error => notify('Error: users not updated', 'warning'),
        }
    );

    return (
        <Button
            label="Block"
            disabled={loading}
            onClick={updateMany}
        >
            <BlockIcon />
        </Button>
    );
}


export default UserListActionButtons;