

interface RType{
    name:string,
    label:string,
    color:string,
    
}

const config: Record<string, RType> = {
    total: {
        name: "total",
        label: "Total",
        
        color: "#33333366",

    },
    profiles: {
        name: "profiles",
        label: "profiles",
        
        color: "#333333",

    },
    male: {
        name: "male",
        label: "Male",
        color: "#0094D0",

    },
    female: {
        name: "female",
        label: "Female",
        color: "#BD0290",

    },
    sponsor: {
        name: "sponsor",
        label: "Sponsor",
        color: "#46d06d",
    }



}

export default config;