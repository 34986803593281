import { AuthProvider, fetchUtils } from "react-admin";

import createJwtManager from "./JwtManager";
import apiRoutes from './apiRoutes';

const jwtManager = createJwtManager(apiRoutes().auth)!


export const fetchJsonInternal = async (url: string, options: fetchUtils.Options = {}) => {
    //await inMemoryJWT.waitForTokenRefresh();


    const token = await jwtManager.getToken();

    if (token) {
        options.user = {
            authenticated: true,
            token: `Bearer ${token}`
        };
    }
    try {
        return await fetchUtils.fetchJson(url, options);
    } catch (err: any) {
        console.error(err.message)
        //1. refresh token
        try {
            jwtManager.eraseToken();
            const newToken = await jwtManager.getToken();
            //2.repeat last fetch operation
            options.user = {
                authenticated: true,
                token: `Bearer ${newToken}`
            };
            return await fetchUtils.fetchJson(url, options);
        } catch (err2) {
            console.log(err2)
            throw err2;
        }
    }

    // if (token) {
    //     options.user = {
    //         authenticated: true,
    //         token: `Bearer ${token}`
    //     };
    //     return await fetchUtils.fetchJson(url, options);
    // } else {
    //     console.log("fetchJson , token=", token)
    //     throw 0;
    // }
}


export const createAuthProvider = (authRoot: string): AuthProvider => {


    return ({
        login: ({ username, password }) => {
            const request = new Request(`${authRoot}/authenticate`, {
                method: 'POST',
                body: JSON.stringify({ username, password }),
                headers: new Headers({ 'Content-Type': 'application/json' }),
                credentials: 'include',
            });

            return fetch(request)
                .then((response) => {
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                    return response.json();
                })
                .then((user) => {
                    jwtManager.setUser(user);
                    const { token, tokenExpiry, id, email } = user;

                    return jwtManager.setToken(token, tokenExpiry);
                });
        },

        logout: () => {
            const request = new Request(`${authRoot}/logout`, {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json' }),
                credentials: 'include',
            });
            jwtManager.eraseToken();
            jwtManager.setUser(undefined);
            return fetch(request).then(() => '/login');
        },

        checkAuth: async () => {
            const token = await jwtManager.getToken();
            if (token) {
                return;
            } else
                throw false;

        },

        checkError: (error) => {
            const status = error.status;
            if (status === 401 || status === 403) {
                jwtManager.eraseToken();
                return Promise.reject();
            }
            return Promise.resolve();
        },

        getPermissions: async () => {
            if (await jwtManager.getToken()) {
                return true
            } else {
                throw 0
            }
            ;

        },
        getIdentity: async () => {
            return jwtManager.getUser();
        }
    });


}


