import apiRoutes from "../apiRoutes";
import fetchJson from "../fetchJson";


const imageUrl_overwrite = async (params: any) => {
    if (params.data.imageUrl?.rawFile instanceof File) {
        const file = params.data.imageUrl?.rawFile;
        const formData = new FormData();
        formData.append("photo", file);

        const resp = await fetchJson(apiRoutes().uploadAsset, { method: 'POST', body: formData });

        const { status, headers, body, json } = resp;
        console.log("uploadAsset:", resp)
        params.data.imageUrl = json.location;

        return params;
    } else {
        return params;
    }
}

const transformModel: Record<string, (param: any) => Promise<any>> = {
    CreditsProduct: imageUrl_overwrite,
    SubscriptionProduct: imageUrl_overwrite,
    mood: imageUrl_overwrite,
}




export default transformModel;