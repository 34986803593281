import { getApiRootUrl } from "./relativeUrl"


const routes = (apiRootPath: string) => ({
    data: `${apiRootPath}`,
    auth: `${apiRootPath}/auth`,
    dashboard: {
        totals: `${apiRootPath}/dashboard/totals`,
        newByDate: `${apiRootPath}/dashboard/new-by-date`,
        newByGenderClid:`${apiRootPath}/dashboard/new-by-gender-clid`,
        activeByDate: `${apiRootPath}/dashboard/active-day-by-day`,
        usersByCity:`${apiRootPath}/dashboard/users-by-city`,
        depositsByDay:`${apiRootPath}/dashboard/deposits-by/day`,
        depositsByMonth:`${apiRootPath}/dashboard/deposits-by/month`,
        retentionByClaims:`${apiRootPath}/dashboard/retention-by-claims`,
    },
    uploadAsset:`${apiRootPath}/uploadAsset`,
    sendEmail: `${apiRootPath}/sendEmail`,
    sendEvent: `${apiRootPath}/sendEvent`,
    batchEmails:`${apiRootPath}/batchEmails`,
    blackList: `${apiRootPath}/blackList`,
})


export default () => routes(getApiRootUrl())
