import { DeleteButton, SaveButton, Toolbar } from "react-admin";


export default (props: any) => {
    return (
        <Toolbar {...sanitizeRestProps(props)} mutationMode="undoable">
            <SaveButton size='small' variant="outlined" color="primary" />
            <DeleteButton size='small' variant="outlined" color="primary" />
        </Toolbar>
    )
}

const sanitizeRestProps = ({ dirtyFields,
    dirtySinceLastSubmit,
    dirtyFieldsSinceLastSubmit,
    hasSubmitErrors,
    hasValidationErrors,
    initialValues,
    modifiedSinceLastSubmit,
    submitError,
    submitErrors,
    submitFailed,
    submitSucceeded,
    submitting,
    valid,
    save,
    validating,
    ...rest
}: any) => rest;
