import { getPhotoRootUrl } from './relativeUrl'

type TPhotoObject = {
    _id?: string,
    id: string,
} 

const variantName = (variant: string) => {
    switch (variant) {
        //case "avatar": return "avatar";
        case "tiny": return "tiny"
        case "small": return "small";
        case "normal": return "normal";
        case "full": return "full";
        case "":
        case "original": return ""
        default: throw new Error("Unknown image type")
    }
}



const getPhotoUrl = (photoId: string, variant: string) => {
    variant = variantName(variant);
    const variantPath = variant ? `${variant}` : "";
    const url = `${getPhotoRootUrl()}/${variantPath}/${photoId}.jpg`;
    return url
}

export default (photoObject: TPhotoObject | string, variant = ""): string => {

    return getPhotoUrl(typeof photoObject === "string" ? photoObject : (photoObject._id || photoObject.id), variant)
}