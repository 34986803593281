import { withStyles } from '@material-ui/core/styles';

const GlobalCss = withStyles(({ zIndex }) => ({
    "@global": {
        // "html": {
        //     margin: "0px",
        //     padding: "0px",
        //     height: "100%"
        // },

        // "body": {
        //     margin: "0px",
        //     padding: "0px",

        // },
        //react-spring-bottom-sheet
        "[data-rsbs-header]": {
            padding: 0
        },
        "[data-rsbs-overlay]": {
            zIndex: zIndex.modal + 1
        },
        "[data-rsbs-backdrop]": {
            zIndex: zIndex.modal + 1
        },
        "[data-rsbs-root]": {
            "&:after": {
                zIndex: zIndex.modal + 1
            }
        },
        ':root': {
            "--rsbs-max-w": "480px",
            "--rsbs-ml": "auto",
            "--rsbs-mr": "auto"
        }
    },

}))(() => null);


export default GlobalCss;