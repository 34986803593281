
import * as React from 'react';
import { getResources } from 'react-admin';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import DefaultIcon from '@material-ui/icons/ViewList';
import DashboardIcon from '@material-ui/icons/Dashboard';

import { TreeView } from '@material-ui/lab';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import StyledTreeItem from './StyledTreeItem';
import inflection from 'inflection';
import _ from 'lodash';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(1),
            // height: 264,
            flexGrow: 1,
            // maxWidth: 400,
        },
    }),
);

export const AppMenu = (props) => {
    const resources = useSelector(getResources).filter(r => r.hasList);
    const groups = React.useMemo(() => _.chain(resources).groupBy(res => res.options.group).map((rs, group) => ({ group, resources: rs })).value() || [], [resources]);

    const classes = useStyles();
    // const open = useSelector<ReduxState, boolean>(state => state.admin.ui.sidebarOpen);
    return (
        <TreeView
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            className={classes.root}
            defaultExpanded={['User']}
        >
            {/* <DashboardMenuItem sidebarIsOpen={open} /> */}
            <StyledTreeItem nodeId='dashboard' to="/" labelIcon={DashboardIcon} labelText='Dashboard' />
            {_.chain(groups).filter(({ group }) => group != 'undefined').map(({ group, resources }) =>
                <StyledTreeItem nodeId={group} key={group} labelText={group} labelIcon={DefaultIcon} >
                    {_.chain(resources).sortBy(r => r.options.order).map(resource => (
                        <StyledTreeItem
                            nodeId={resource.name}
                            key={resource.name}
                            to={{
                                pathname: `/${resource.name}`,
                                state: { _scrollToTop: true },
                            }}
                            labelText={(resource.options && resource.options.label) || inflection.humanize(inflection.pluralize(resource.name))}
                            labelIcon={resource.icon ? resource.icon : DefaultIcon}
                            onClick={props.onMenuClick}
                        // sidebarIsOpen={open}
                        />
                    )).value()}
                </StyledTreeItem>
            ).value()
            }
            {
                _.chain(groups.find(({ group }) => group == 'undefined')?.resources || [])
                    .sortBy(r => r.options.order)
                    .map(resource => (
                        <StyledTreeItem
                            nodeId={resource.name}
                            key={resource.name}
                            to={{
                                pathname: `/${resource.name}`,
                                state: { _scrollToTop: true },
                            }}
                            labelText={(resource.options && resource.options.label) || inflection.humanize(inflection.pluralize(resource.name))}
                            labelIcon={resource.icon ? resource.icon : DefaultIcon}
                            onClick={props.onMenuClick}
                        // sidebarIsOpen={open}
                        />
                    )).value()
            }
            {/* <StyledTreeItem nodeId='Settings' to={{ pathname: '/settings/default' }} labelIcon={DefaultIcon} labelText='Settings' /> */}

        </TreeView>
    );
};