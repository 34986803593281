import DeferredAggregate from "../../../utils/DeferredAggregate"
import { LocationInfo } from "./types"


//https://ip-api.com/docs/api:batch
interface IipSingleLocation {
    currency: string
    status: string,
    country: string,
    countryCode: string,
    region: string,
    regionName: string,
    city: string,
    zip: string,
    lat: number,
    lon: number,
    timezone: number,
    isp: number,
    org: number,
    as: number,
    query: number,

}

const batchRequest = new DeferredAggregate<string, IipSingleLocation>(500, async (ids) => {
    // const url = 'http://ip-api.com/batch';
    const url = 'https://tenmlasoszr5i6o3o5xeqg7lei0pnyya.lambda-url.eu-west-1.on.aws/';
    const response = await fetch(url, { method: 'POST', body: JSON.stringify(ids) })
    const data = await response.json() as IipSingleLocation[];
    return data.reduce((acc, rec) => ({ [rec.query]: rec, ...acc }), {})
})

const getLocationInoByIp = async (ip: string): Promise<LocationInfo> => {
    const { lat, lon, status, countryCode, country, city } = await batchRequest.requestOne(ip);
    if (status === "success") {
        return ({

            latitude: lat,
            longitude: lon,
            flagUrl: `https://flagcdn.com/32x24/${countryCode.toLowerCase()}.png` ,
            cityName: city,
            countryName: country
        })
    }
    else
        throw new Error("Not found")
}

export { getLocationInoByIp }