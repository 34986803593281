import { Box, Chip, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import get from "lodash/get";


const useStyles = makeStyles(
    {
        root: {
            padding:"0.5em"
        },
        item: {
           margin: "0.2em"
        },

    },
    { name: 'RaImageField' }
);

const TagsField = (props: any) => {
    const {
        className,
        classes: classesOverride,
        fieldKey = "name",
        emptyText, record, source, src, title, ...rest
    } = props;
    const sourceValue = get(record, source!);
    const classes = useStyles(props);

    return (

        <Box className={classes.root}>
            {sourceValue.map((item: any) => (
                // <li key={item[fieldKey]}>{item[fieldKey]}</li>
                <Chip key={item[fieldKey]} variant="outlined" color="primary" label={item[fieldKey]} className={classes.item} />
            ))}
        </Box>
    )
}

export default TagsField