
import { ReactComponent as MaleIcon } from '../../assets/male-solid.svg';
import { ReactComponent as FemaleIcon } from '../../assets/female-solid.svg'
import { ReactComponent as PremiumActiveIcon } from '../../assets/crown-vip-filled-24.svg';

// import { ReactComponent as NoPremiumActiveIcon } from '../../assets/chess-pawn-solid.svg'
// import { ReactComponent as ZombieIcon } from '../../assets/zombie-sign.svg'
import ZombieIcon from '@material-ui/icons/HourglassEmptyOutlined';
import AdminBlockedIcon from '@material-ui/icons/CancelOutlined';
//import AdminBlockedIcon from '@material-ui/icons/SentimentVeryDissatisfiedOutlined';
import OkAccountIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import VisibilityOnIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';
import VerifiedIcon from '@material-ui/icons/DoneOutlined';
import NotVerifiedIcon from '@material-ui/icons/CloseOutlined';
import SponsorIcon from '@material-ui/icons/MonetizationOnOutlined';
// import NotSponsorIcon from '@material-ui/icons/MoneyOffOutlined';
import AdminNotesIcon from '@material-ui/icons/ViewHeadlineOutlined';

import ProfileValidIcon from '@material-ui/icons/LinkOutlined';
import ProfileInvalidIcon from '@material-ui/icons/LinkOffOutlined';

import { Box, SvgIcon, Tooltip, TooltipProps } from '@material-ui/core';


// eslint-disable-next-line import/no-anonymous-default-export
export default function StatusField({ record }: any) {
    if (record) {
        const { profile, adminBlocked, verified, profileValid, premium, adminData } = record;
        const { gender, isSponsor } = profile;


        return (
            <Box display="flex">
                {verified ? <TT title="Verified"><VerifiedIcon htmlColor='green' /></TT> : <TT title="Unverified"><NotVerifiedIcon htmlColor='red' /></TT>}
                {profileValid ? <TT title="Profile Valid"><ProfileValidIcon htmlColor='green' /></TT> : <TT title="Profile invalid"><ProfileInvalidIcon htmlColor='red' /></TT>}
                {!gender ? <ZombieIcon htmlColor='#ccc' /> :
                    <SvgIcon component={gender === "male" ? MaleIcon : FemaleIcon} viewBox="0 0 256 512" htmlColor={gender === "male" ? "royalblue" : gender === "female" ? "hotpink" : 'grey'} />
                }
                {isSponsor ? <TT title="Sponsor"><SponsorIcon htmlColor='blue' /></TT> : <TT title="Not sponsor"><SponsorIcon htmlColor='hotpink' /></TT>}
                {premium?.isActive ? <SvgIcon component={PremiumActiveIcon} htmlColor='#A383FF' /> : <SvgIcon component={PremiumActiveIcon} htmlColor="#ccc" />}


                {adminBlocked ? <TT title="Blocked"><AdminBlockedIcon color='error' /></TT> : <TT title="Account OK"><OkAccountIcon htmlColor='green' /></TT>}
                {adminData?.notes ? <TT title={adminData.notes}><AdminNotesIcon /></TT> : <></>}
            </Box >
        )
    }
    return null;
}

// interface IconWithTooltipProps {
//     title: string,
//     icon: SvgIcon
// }
const TT = (props: TooltipProps) => {
    return <Tooltip {...props} />
}