import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Badge, Typography, TypographyProps } from '@material-ui/core'
import { FieldProps, useQueryWithStore } from "react-admin";
import moment from 'moment';
import MessageField from '../Message/MessageField';


interface IMsg {
    id: string,
    conversation: string
    sender: string,
    to: string,
    text: string,
    sent: Date,
    received: Date,
    type: string,
    content: string

}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex"
        },
        sent: {
            padding: theme.spacing(0.5, 1)
        },
        sender: {
            padding: theme.spacing(0.5, 1),
            fontSize: "0.8em"
        },
        content: {
            padding: theme.spacing(0.5, 1),
            borderRadius: "6px",
            borderColor: theme.palette.primary.light,
            borderStyle:"solid",
            borderWidth:"2px",
            backgroundColor: theme.palette.action.selected,
            // color: theme.palette.primary.contrastText
        }
    }),
);

export default (props: FieldProps) => {
    const classes = useStyles();
    const { record } = props;
    if (record) {
        const { sender, to, content, type, text, sent, received } = record as IMsg;

        const { loaded, error, data } = useQueryWithStore({
            type: 'getMany',
            resource: 'user',
            payload: { ids: [sender, to] }
        });
        if (loaded && data) {

            const users: any = {
                // [data[0].id]: data[0],
                // [data[1].id]: data[1]
            };

            if (data.length > 0) users[data[0].id] = data[0];
            if (data.length > 1) users[data[1].id] = data[1];

            const senderUser = users[sender];
            
            return (
                <div className={classes.root}>
                    <div className={classes.sent}>
                        <Typography component="span" variant="caption" >
                            {moment(sent).format('MMM-DD HH:mm:ss')}
                        </Typography>
                    </div>
                    <div className={classes.sender}>

                        {/* <Typography component="span" variant="caption"> */}
                        {senderUser?.profile?.nickname || "?unknown?"}
                        {/* </Typography> */}
                    </div>
                    
                    <div className={classes.content}>
                        {/* <Typography component="span" variant="caption">
                            {content || text}
                        </Typography> */}

                        <MessageField {...props} source="content" type="type" />



                    </div>
                    {received &&
                        <Badge badgeContent=" "
                            color="primary"
                            variant="dot"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        ></Badge>
                    }
                </div>
            )
        }
    }
    return null;

}