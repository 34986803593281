import { ChoicesInputProps } from "ra-core";
import { SelectInput, TextFieldProps } from "ra-ui-materialui";



interface CurrencySelectorProps extends React.PropsWithChildren<ChoicesInputProps<TextFieldProps>> {
    type?: "user" | "product"
}

const currencies = {
    user: ["USD", "EUR", "GBP"].map(c => ({ id: c, name: c })),
    product: ["USD", "EUR", "GBP", "BUSD", "USDT"].map(c => ({ id: c, name: c }))
}


export default function CurrencySelector({ type = "user", ...rest }: CurrencySelectorProps) {



    return (<SelectInput
        choices={currencies[type]}
        {...rest}
    />)
}