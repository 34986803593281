import { Checkbox, FormControlLabel, Switch } from "@material-ui/core";
import { ToggleButton } from "@material-ui/lab";
import { prototype } from "apexcharts";
import { FormEventHandler } from "react";
import { useDataProvider } from "react-admin";
import { Photo } from "../types";


interface Props {
    photo: Photo
}
const singleItem = { pagination: { page: 1, perPage: 1 }, sort: { field: "id", order: 'DESC' } };

export default ({ photo }: Props) => {
    const dataProvider = useDataProvider();

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {

        // const isMain = event.target.checked;
        if (!photo.isMain && event.target.checked) {
            console.log("Changing Is Main, checked=", event.target.checked, "photo", photo);
            const respUnmain = await dataProvider.getList<Photo>("photo", { filter: { user: photo.user, isMain: true }, ...singleItem });
            console.log("respUnmain", respUnmain);
            if (respUnmain.data.length > 0) {
                const unmain = respUnmain.data[0];
                await dataProvider.update<Photo>('photo', { id: unmain.id, data: { isMain: false }, previousData: unmain });
            }
            await dataProvider.update<Photo>('photo', { id: photo.id, data: { isMain: true }, previousData: photo });
            console.log("Done");
        }
    }

    return (
        <FormControlLabel
            control={<Checkbox checked={photo.isMain} onChange={handleChange} name="checkedA" disabled={photo.moderation != "accepted"} />}
            label="Main"
        />

    )
}