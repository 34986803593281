import React from "react";
import { AutocompleteInput, Filter, FilterProps, ReferenceInput, SelectInput, TextField, TextInput } from "ra-ui-materialui";
import { Chip, makeStyles } from "@material-ui/core";
import { NullableBooleanInput } from "react-admin";


const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));

const QuickFilter = ({ label }: any) => {
    //const translate = useTranslate();
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={(label)} />;
};


const PhotoFilter = (props: any) => (
    <Filter {...props}>
        {/* <TextInput label="Search" source="q" alwaysOn /> */}
        {/* <QuickFilter source="moderatedAt" label="Only Moderated" defaultValue={"!null"}/>
        <QuickFilter source="moderatedAt" label="Only Not Moderated" defaultValue={"null"}/> */}


        <SelectInput source="moderation" alwaysOn choices={[
            { id: 'null', name: 'Not moderated' },
            { id: 'any', name: 'Moderated' },
            { id: 'rejected', name: 'Rejected' },
            { id: 'accepted', name: 'Accepted' },
            { id: 'all', name: 'All' },
        ]} />
        <NullableBooleanInput label="Approved" source="approved" alwaysOn />
        <ReferenceInput label="User" source="user" reference="user" allowEmpty alwaysOn filterToQuery={txt => ({ profile: { nickname: txt } })}>
            <AutocompleteInput optionText="profile.nickname" />
        </ReferenceInput>
        <ReferenceInput label="Email" source="user" reference="user" allowEmpty alwaysOn filterToQuery={txt => ({ email: txt  })}>
            <AutocompleteInput optionText="email" />
        </ReferenceInput>
        {/* <ReferenceInput label="User" source="userId" reference="users" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput> */}
    </Filter>
)


export default PhotoFilter;