import { BooleanInput, Edit, SimpleForm, TextInput } from "react-admin";




export default function EmailDomainEdit(props) {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="id" label={"Domain"} />
                <BooleanInput source="isDisposable" />
            </SimpleForm>
        </Edit>
    )
}