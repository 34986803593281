import { useContext } from "react";
import { ButtonProps, ShowButton, Button, linkToRecord, DataProviderContext } from "react-admin";
import { useHistory } from "react-router-dom";
import SubjectIcon from '@material-ui/icons/Subject';
import { IconButton } from "@material-ui/core";


const singleItem = { pagination: { page: 1, perPage: 1 }, sort: { field: "id", order: 'DESC' } };


export default ({ record }: ButtonProps) => {

    const dataProvider = useContext(DataProviderContext);
    const history = useHistory();
    const handleClick = async () => {
        if (record) {
            const users = [record.reporter, record.about];
            const conversations = await dataProvider.getList('conversation', { filter: { users, withDeleted: 1 }, ...singleItem });

            const link = linkToRecord('/conversation', conversations.data[0].id, 'show');
            history.push(link);
        }
    }


    return (
        <IconButton onClick={handleClick} >
            <SubjectIcon />
        </IconButton>)
}