import { Datagrid, DateField, List, NumberField, ReferenceField, TextField } from "react-admin";


export default function DepositsList(props) {

    return (
        <List {...props}
            perPage={25}
            sort={{ field: 'createdAt', order: 'DESC' }}
        >
            <Datagrid rowClick="edit">
                {/* <TextField source="id" /> */}
                <ReferenceField source="user" reference="user">
                    <TextField source="profile.nickname" />
                </ReferenceField>
                <ReferenceField source="product" reference="product">
                    <TextField source="title" />
                </ReferenceField>

                <NumberField source="amount" />
                <TextField source="currency" />
                <DateField source="createdAt" />

                <TextField source="refId" />
                <TextField source="provider" />
                <ReferenceField source="transaction" reference="transaction">
                    <TextField source="id" />
                </ReferenceField>
            </Datagrid>
        </List>
    )
}