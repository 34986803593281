import { AppBar, Box, Dialog, IconButton, Modal, ModalProps, Toolbar } from "@material-ui/core"
import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles';
import photoUrl from "../../utils/photoUrl"
import { LandmarkType, Photo, Pos } from "../types"
// import { Toolbar } from "react-admin";

import CloseIcon from '@material-ui/icons/Close';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

interface PhotoPreviewProps {
    photo: Photo,
    open: boolean,
    onClose: () => void,
}

const styles = (theme: Theme) => createStyles({

    root: {

    },
    canvas: {
        width: "100%",
        height: "100%",
        // aspectRatio:"2/3",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    }

})
const useStyles = makeStyles(styles)

export const PhotoPreview = ({ photo, open, onClose }: PhotoPreviewProps) => {
    const classes = useStyles()

    const [canvas, setCanvas] = useState<HTMLCanvasElement>()
    const setCanvasCallback = useCallback((canvas: HTMLCanvasElement) => {
        setCanvas(canvas)
    }, [])

    useLayoutEffect(() => {

        if (photo && canvas) {
            const image = new Image()
            image.src = photoUrl(photo, "normal")
            const ctx = canvas.getContext("2d")
            image.onload = () => {

                console.log(photo)
                canvas.width = image.naturalWidth;
                canvas.height = image.naturalHeight;
                ctx.drawImage(image, 0, 0);
                if (photo.metadata) {
                    const faces = photo.metadata.DetectFaces.FaceDetails;
                    faces.forEach(face => {
                        ctx.beginPath();
                        ctx.rect(face.BoundingBox.Left * canvas.width, face.BoundingBox.Top * canvas.height, face.BoundingBox.Width * canvas.width, face.BoundingBox.Height * canvas.height);
                        ctx.stroke();
                    })

                    faces.forEach((face) => {
                        const landmarks: { [key in LandmarkType]?: { x, y } } = Object.fromEntries<{ x: number, y: number }>(
                            face.Landmarks.map(landmark => [landmark.Type, { x: landmark.X * canvas.width, y: landmark.Y * canvas.height }]))
                        // const eyesRectangle = { x: landmarks.leftEyeLeft.x, y: landmarks.leftEyeLeft.y, width: landmarks.rightEyeRight.x - landmarks.leftEyeLeft.x, height: landmarks.rightEyeRight.y - landmarks.leftEyeLeft.y }
                        const { leftEyeLeft, leftEyeDown, leftEyeUp, rightEyeRight, rightEyeDown, rightEyeUp,
                            leftEyeBrowLeft,rightEyeBrowRight,
                            leftEyeBrowUp,rightEyeBrowUp,
                                                        
                         } = landmarks;


                        ctx.strokeStyle = "red";
                        ctx.beginPath();
                        ctx.moveTo(leftEyeLeft.x,leftEyeUp.y);
                        ctx.lineTo(rightEyeRight.x, rightEyeUp.y);
                        ctx.lineTo(rightEyeRight.x, rightEyeDown.y);
                        ctx.lineTo(leftEyeLeft.x, leftEyeDown.y);
                        ctx.closePath()
                        // ctx.moveTo(landmarks.leftEyeLeft.x, landmarks.leftEyeLeft.y);
                        // ctx.lineTo(landmarks.rightEyeRight.x, landmarks.rightEyeRight.y);

                        ctx.stroke();
                    })
                    // context.beginPath();
                    // context.moveTo(0, 0)
                    // context.lineTo(640, 100)
                    // context.stroke();
                }
            }
        }

    }, [photo, canvas])


    return (
        <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>

            <Toolbar variant="dense" disableGutters>
                <IconButton onClick={(e) => onClose()}>
                    <CloseIcon />
                </IconButton>
            </Toolbar>

            <canvas ref={setCanvasCallback} />
            {/* {open &&
                    // <img src={photoUrl(photo, "normal")} />
                    // <canvas className={classes.canvas} style={{                        
                    //     backgroundImage:`url(${photoUrl(photo, "normal")})`}}></canvas>
                    // // <></>
                } */}

        </Dialog>
    )
}