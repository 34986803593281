import { AutocompleteArrayInput, AutocompleteArrayInputProps } from "react-admin";

const countries = [
    { id: 'UA', name: 'Ukraine' },
    { id: 'UK', name: '--Remove Me' },
    { id: 'PL', name: 'Poland' },
    { id: 'DE', name: 'Germany' },
    { id: 'LT', name: 'Lithuania' },
    { id: 'LV', name: 'Latvia' },
    { id: 'EE', name: 'Estonia' },
    { id: 'GB', name: 'United Kingdom' },
    { id: 'IL', name: 'Israel' },

    { id: 'FR', name: 'France' },
    { id: 'IT', name: 'Italy' },
    { id: 'ES', name: 'Spain' },
    { id: 'NL', name: 'Netherlands' },
    { id: 'SE', name: 'Sweden' },
    { id: 'AT', name: 'Austria' },
    { id: 'BE', name: 'Belgium' },
    { id: 'CH', name: 'Switzerland' },
    { id: 'NO', name: 'Norway' },
    { id: 'FI', name: 'Finland' },
    { id: 'GR', name: 'Greece' },
    { id: 'HU', name: 'Hungary' },
    { id: 'IE', name: 'Ireland' },
    { id: 'DK', name: 'Denmark' },
    // { id: 'LU', name: 'Luxembourg' },

    { id: 'PT', name: 'Portugal' },
    { id: 'RO', name: 'Romania' },
    { id: 'SI', name: 'Slovenia' },
    { id: 'SK', name: 'Slovakia' },
    { id: 'BG', name: 'Bulgaria' },
    { id: 'HR', name: 'Croatia' },
    { id: 'CZ', name: 'Czech Republic' },
    { id: 'TR', name: 'Turkey' },
    { id: 'US', name: 'United States' },
    { id: 'CA', name: 'Canada' },
    // { id: 'RU', name: 'Russia' },
    // { id: 'CN', name: 'China' },
    // { id: 'IN', name: 'India' },
    // { id: 'TH', name: 'Thailand' },
    // { id: 'VN', name: 'Vietnam' },
].sort((a, b) => a.id.localeCompare(b.id))
export function CountriesSelector(props: AutocompleteArrayInputProps) {
    return <AutocompleteArrayInput
        choices={countries}

        {...props}


    />
}