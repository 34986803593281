import { useEffect, useRef } from "react";
import { Content, JSONEditor, JSONEditorPropsOptional } from "vanilla-jsoneditor";



interface Props extends JSONEditorPropsOptional {
    className?: string,
    style?: React.CSSProperties,
}

export default function ReactJsonEditor({ className, style, ...props }: Props) {
    const refContainer = useRef(null);
    const refEditor = useRef<JSONEditor | null>(null);


    useEffect(() => {
        // create editor
        // console.log("create editor", refContainer.current);

        refEditor.current = new JSONEditor({
            target: refContainer.current!,
            props: {}
        });

        return () => {
            // destroy editor
            if (refEditor.current) {
                // console.log("destroy editor");
                refEditor.current.destroy();
                refEditor.current = null;
            }
        };
    }, []);

    // update props
    useEffect(() => {
        if (refEditor.current) {
            // console.log("update props", props);
            refEditor.current.updateProps(props);
        }
    }, [props]);

    return <div className={className} style={style} ref={refContainer}></div>;
}