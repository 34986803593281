import { BooleanInput, Create, CreateProps, DateInput, Edit, EditProps, NullableBooleanInput, NumberInput, SelectInput, SimpleForm, TextInput } from "react-admin";
import { JsonObjectInput } from "./JsonInput";
import { profileFields } from "./consts";
import ProfileSelectInput from "../User/UserEdit/ProfileSelectInput";
import { LANGUAGES } from "../utils/constants";
// "info","success","warning","error","default"
const AnnounceCreate = (props: CreateProps) => (
    <Create {...props}>
        <SimpleForm redirect="list">

            <BooleanInput variant="outlined" source="active" />

            <SelectInput variant="outlined" source='language'
                label="UI language"
                helperText={false}
                choices={LANGUAGES} />
            {/*  */}
            <TextInput variant="outlined" source="email" label="Email for testing" helperText={false}/>
            <NullableBooleanInput variant="outlined" source="hasPhoto" helperText={false}/>
            <NullableBooleanInput variant="outlined" source="isPremium" helperText={false}/>
            <ProfileSelectInput variant="outlined" source="profile.gender" label="Gender" helperText={false} allowEmpty={true}  />
            <ProfileSelectInput variant="outlined" source="profile.isSponsor" label="Is Sponsor" allowEmpty={true} helperText={false}  />
            <ProfileSelectInput variant="outlined" source="profile.purpose" label="Purpose" allowEmpty={true} helperText={false} pre={[{id:"na",name:"NA"}]}  />
            <ProfileSelectInput variant="outlined" source="profile.datingAs" label="Dating as" allowEmpty={true} helperText={false}  />
            <ProfileSelectInput variant="outlined" source="profile.sexOrientation" label="Sex orientation" allowEmpty={true} helperText={false}  />
            {/*  */}
            <NumberInput variant="outlined" source="delay" />
            <DateInput variant="outlined" source="startsOn" />
            <DateInput variant="outlined" source="endsOn" />
            <SelectInput variant="outlined" source="type"
                choices={[
                    { id: 'info', name: "Info" },
                    { id: 'success', name: "Success" },
                    { id: 'warning', name: "Warning" },
                    { id: 'error', name: "Error" },
                    { id: 'default', name: "Default" },
                ]}
            />
            <TextInput variant="outlined" source="content" label="Content" multiline rows={4} helperText={false} fullWidth />
        </SimpleForm>
    </Create>
);
export default AnnounceCreate;