import { Datagrid, List, ListProps, NumberField, Pagination, ReferenceField, TextField } from "react-admin";
import JsonFormatter from 'react-json-formatter';
import { Typography } from '@material-ui/core';
import get from 'lodash/get';

import { DateTimeField } from "../User/UserList/UserFields";
import CreditLogFilter from "./CreditLogFilter";

const CreditsLogPagination = (props: any) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const CreditInfoShow = (props: any) => {
    const { className, source, record = {}, emptyText, ...rest } = props;
    const value = get(record, source);
    if (value) {
        const text = JSON.stringify(value);
        const jsonStyle = {
            propertyStyle: { color: 'red' },
            stringStyle: { color: 'green' },
            numberStyle: { color: 'darkorange' }
        };
        return <Typography><JsonFormatter json={text} tabWith={4} jsonStyle={jsonStyle} /></Typography>
    } else {
        return null;
    }
}


export default (props: ListProps) => {

    return (
        <List {...props}
            perPage={25}
            pagination={<CreditsLogPagination />}
            sort={{ field: 'createdAt', order: 'DESC' }}
            filters={<CreditLogFilter />}>
            <Datagrid rowClick="edit"
                expand={<CreditInfoShow source="data" />}
            >
                {/* <TextField source="id" /> */}
                <ReferenceField label="User" source="user" reference="user">
                    <TextField source="profile.nickname" />
                </ReferenceField>
                {/* <TextField source="user" /> */}
                <NumberField source="credits" />
                <TextField source="type" />
                <TextField source="action" />
                <DateTimeField source="createdAt" />
                {/* <DateField source="updatedAt" /> */}
                {/* <TextField source="data" /> */}

            </Datagrid>
        </List>
    )
}