import React from 'react';
import { Admin, Resource, ListGuesser, EditGuesser, ShowGuesser, defaultTheme } from 'react-admin';

import { UserList, UserEdit, UserCreate } from './User';
import { PhotoList } from './Photo';
import { createAuthProvider } from './utils/authProvider2';
import logo from './logo.svg';
import './App.css';

import MessageList from './Message/MessageList';

import { ConversationShow, ConversationList } from './Conversation';
import Dashboard from './Dashboard';
import apiRoutes from './utils/apiRoutes';

import GlobalCss from './GlobalCss';

import 'react-spring-bottom-sheet/dist/style.css'
import ReportList from './Report/ReportList';
import { CreditProductCreate, CreditProductEdit, ProductList, SubscriptionProductCreate, SubscriptionProductEdit } from './Product';

import { createDataProvider } from './utils/dataProvider';
import CreditsLogList from './CreditsLog/CreditsLogList';
import ClaimedDayList from './ClaimedDay/ClaimedDayList';
import { MailBatchCreate, MailBatchEdit, MailBatchList } from './MailBatch';
import { TransactionList, TransactionShow } from './Transaction';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AnnounceList, AnnounceCreate, AnnounceEdit } from './Announce';
import DepositsList from './Deposits/DepositsList';
import { AppLayout } from './AppMenu/AppLayout';
import { createMuiTheme } from '@material-ui/core';
import { EmailDomainCreate, EmailDomainEdit, EmailDomainList } from './EmailDomain';
import { MoodCreate, MoodEdit, MoodList } from './Moods';
import { UserLikeList } from './UserLike';
import { UserLikeMatchList } from './UserLikeMatch';
import { SettingEdit } from './Settings';


const authProvider = createAuthProvider(apiRoutes().auth)
const dataProvider = createDataProvider(apiRoutes().data);

const queryClient = new QueryClient()

const theme = createMuiTheme({
    ...defaultTheme,
    sidebar: {
        width: 200, // The default value is 240
        closedWidth: 70, // The default value is 55
    },
} as any);

function App() {
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <GlobalCss />
                <Admin theme={theme} dashboard={Dashboard} dataProvider={dataProvider} authProvider={authProvider} layout={AppLayout}>
                    {/* sample usage with a user resource in the admin */}
                    <Resource name="photo" list={PhotoList} options={{ group: "User" }} />
                    <Resource name="user" list={UserList} edit={UserEdit} create={UserCreate} options={{ group: "User" }} />
                    <Resource name="message" list={MessageList} edit={EditGuesser} options={{ group: "User" }} />
                    <Resource name="conversation" list={ConversationList} edit={EditGuesser} show={ConversationShow} options={{ group: "User" }} />
                    <Resource name="userlike" list={UserLikeList} edit={EditGuesser} show={ShowGuesser} options={{ group: "User", label: "Likes" }} />
                    <Resource name="userlikematch" list={UserLikeMatchList} edit={EditGuesser} show={ShowGuesser} options={{ group: "User", label: "Matches" }} />
                    <Resource name="city" />
                    <Resource name="country" />

                    <Resource name="report" list={ReportList} options={{ group: "User" }} />

                    <Resource name="SubscriptionProduct" list={ProductList} edit={SubscriptionProductEdit} create={SubscriptionProductCreate}
                        options={{ label: "Subscriptions", group: "Product" }} />
                    <Resource name="CreditsProduct" list={ProductList} edit={CreditProductEdit} create={CreditProductCreate}
                        options={{ label: "Credits", group: "Product" }}
                    />
                    {/* <Resource name="Locale" list={LocaleList} edit={LocaleEdit}/> */}
                    <Resource name="product" />
                    <Resource name="CreditsLog" list={CreditsLogList} edit={EditGuesser} options={{ group: "Finance" }} />
                    <Resource name="ClaimedDay" list={ClaimedDayList} edit={EditGuesser} options={{ group: "Finance" }} />
                    <Resource name="transaction" list={TransactionList} show={TransactionShow} options={{ group: "Finance" }} />
                    <Resource name="deposit" list={DepositsList} options={{ group: "Finance" }} />
                    <Resource name="mailbatch" options={{ label: "Mail Batch", group: "Tools" }} list={MailBatchList} edit={MailBatchEdit} create={MailBatchCreate} />
                    <Resource name="announce" list={AnnounceList} edit={AnnounceEdit} create={AnnounceCreate} options={{ group: "Tools" }} />
                    <Resource name="mood" list={MoodList} edit={MoodEdit} create={MoodCreate} options={{ group: "Tools" }} />
                    <Resource name="emaildomain" list={EmailDomainList} edit={EmailDomainEdit} create={EmailDomainCreate} options={{ group: "Tools", label: "Email Domains" }} />
                    <Resource name="settings" edit={SettingEdit} />
                </Admin>
            </QueryClientProvider>
        </>
    );
}

export default App;
