import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    FormControlLabel,
    IconButton,
    Typography
} from "@material-ui/core"
import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { DateField, ReferenceField, TextField } from "ra-ui-materialui";
import { BooleanField, FieldProps, useGetIdentity, useGetMany } from "react-admin";
import fileDownload from "js-file-download"
import { DateTimeField } from "../../User/UserList/UserFields";

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PublishIcon from '@material-ui/icons/Publish';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import TelegramIcon from '@material-ui/icons/Telegram';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import photoUrl from "../../utils/photoUrl";
import { Moderation, Photo } from "../types";
import MainPhotoSelect from "./MainPhotoSelect";
import ModerateControl from "./ModerateControl";
import VideoPlayer from "./VideoPlayer";
import React, { useCallback } from "react";
import sendEvent from "../../utils/sendEvent";
import { ToggleButton } from "@material-ui/lab";
import { get } from "lodash";


const styles = (theme: Theme) => createStyles({

    root: {},
    media: {
        height: 320,
        backgroundSize: "contain"
    },

    title: {
        paddingBottom: '0.5em',
    },
    // actionSpacer: {
    //     display: 'flex',
    //     flexDirection: "column",

    //     // justifyContent: 'space-around',
    // },
    cardActions: {
        flexDirection: "column",
    },
    content: {
        padding: "16px 32px"
    }
})
const useStyles = makeStyles(styles)
interface Props {
    photo: Photo,
    onItemSelect?: () => void
    onUpdate?: (data: Partial<Photo>) => void
}


const PhotoCard = ({ photo, onItemSelect, onUpdate }: Props) => {
    // const { data: user } = useGetMany("user", [photo.user])
    const { identity, loaded } = useGetIdentity()
    // console.log({ identity })
    const classes = useStyles()

    const handleDownloadPhoto = useCallback(async () => {
        const res = await fetch(photoUrl(photo, "full"));
        const blob = await res.blob();
        fileDownload(blob, `${photo.id}.jpeg`);
    }, [photo])

    const handlePublishPhoto = useCallback(async () => {
        sendEvent("photo-event", "publish", { photoId: photo.id })
    }, [photo])

    const handleApproveClick = useCallback(() => {
        if (identity && photo.moderation) {
            onUpdate?.({ approved: true, approvedAt: new Date(), approvedBy: identity.id.toString() })
        }
    }, [photo, onUpdate, identity])

    const handleModerateUpdate = useCallback((newValue: Moderation) => {
        const approval = { approved: !!newValue, approvedAt: new Date(), approvedBy: identity.id.toString() };
        onUpdate?.({ moderation: newValue, moderatedAt: new Date(), ...approval })
    }, [onUpdate, identity])

    const mediatype = photo.mimetype.split('/')[0];

    return (
        <Card key={photo.id} className={classes.root}>
            <CardHeader
                title={
                    <ReferenceField label="User" source="user" reference="user" basePath="/user" record={photo}>
                        <UserField />
                    </ReferenceField>
                }
                subheader={<>Created: <DateTimeField source="createdAt" record={photo} /></>}

                action={<Box m={1} gridTemplateColumns={"1fr 1fr"} display={"grid"}>

                    <IconButton onClick={() => handleDownloadPhoto()} size="small">
                        <CloudDownloadIcon />
                    </IconButton>
                    <IconButton href={photoUrl(photo, "full")} target="_blank" size="small">
                        <OpenInNewIcon />
                    </IconButton>
                    <IconButton onClick={() => handlePublishPhoto()} size="small" title="publish to telegram">
                        <TelegramIcon />
                    </IconButton>
                </Box>}

            />
            {mediatype === 'image' &&
                <CardMedia image={photoUrl(photo, 'normal')} className={classes.media}
                    onClick={() => {
                        onItemSelect?.()
                    }}
                />
            }
            {mediatype === 'video' &&
                <div className={classes.media}>
                    <VideoPlayer media={photo} />
                </div>
            }
            {/* <Typography variant="body2">Created: <DateTimeField source="createdAt" record={photo} /></Typography> */}
            {/* <div>{user?.[0]?.profile?.nickname}</div> */}
            <CardContent className={classes.content}>
                <MainPhotoSelect photo={photo} />

                <Typography variant="body2">Created: <DateTimeField source="createdAt" record={photo} /></Typography>

                <Typography component="div">
                    {photo.moderation
                        ? <div>{photo.moderation} at <DateTimeField source='moderatedAt' record={photo} /></div>
                        : <div>moderation: NA</div>}
                </Typography>
                <Typography>
                    {photo.reasons.join(",")}
                </Typography>

            </CardContent>
            {loaded && <CardActions className={classes.cardActions}>
                <ModerateControl value={photo.moderation}
                    onChange={handleModerateUpdate} />
                <Box mt={1}>
                    <ToggleButton selected={photo.approved} disabled={!photo.moderation} onChange={handleApproveClick} value={""}>
                        {photo.approved ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />} Approved
                    </ToggleButton>
                </Box>
            </CardActions>}

        </Card>
    )
}

export default PhotoCard;


const UserField = (props: FieldProps) => {
    const { record = {} } = props;
    const { deleted, adminBlocked, profileValid, settings = {} } = record as any
    const { accountVisibility } = settings as any;

    const disable = deleted || adminBlocked || !profileValid
        || accountVisibility === "deactivated" || accountVisibility === "hidden"

    return (
        <Box border={"1px #ccc solid"} px={1} py={0.5} borderRadius={"8px"}>
            <Typography variant="body1" style={{ textDecoration: disable && "line-through" }}>
                {get(record, "profile.nickname")}
            </Typography>
            <Typography variant="subtitle2">{get(record, "profile.gender")}, {get(record, "profile.age")}</Typography>
        </Box>
    )
    
}



