import { Chip, SvgIcon } from "@material-ui/core";
import { ReactElement } from "react";
import { FieldProps } from "react-admin";

const knownIds = { "gclid": "google", "fbclid": "facebook", "ysclid": "yandex" }
const iconsRoot = "../../assets/social/"
const Icons: Record<string, (props: any) => ReactElement> = import.meta.glob("../../assets/social/*.svg", {
    import: "ReactComponent",
    eager: true
})

console.log({ Icons })

const Icon = ({ id, ...props }) => Icons[`${iconsRoot}${id}.svg`]({ ...props })

export const MetadataField = ({ record }: FieldProps) => {
    if (record?.metadata) {
        const clids = Object.keys(record.metadata).filter(key => key.endsWith("clid"));

        return (
            <>
                {clids.map(clid =>
                    <Chip key={clid} label={clid}
                        icon={knownIds[clid] && <Icon id={knownIds[clid]} viewBox="0 0 512 512" width={24} height={24} />} />)}
            </>)

    } else
        return null

}