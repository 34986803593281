import React from "react";
import { Filter, ReferenceInput, SelectInput } from "ra-ui-materialui";
import { LANGUAGES } from "../utils/constants";



export default ( props: any ) => (
    <Filter { ...props }>
        
        <SelectInput variant="outlined" source="type"
            choices={[
                { id: 'info', name: "Info"},
                { id: 'success', name: "Success"},
                { id: 'warning', name: "Warning"},
                { id: 'error', name: "Error"},
                { id: 'default', name: "Default"},
            ]}
            />
        <SelectInput variant="outlined" source='language'
                label="UI language"
                helperText={false}
                choices={LANGUAGES} />
    </Filter>
)