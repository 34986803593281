
import { ArrayInput, SimpleFormIterator, NumberInput, SelectInput, AutocompleteArrayInput, ReferenceArrayInput, SelectArrayInput } from "react-admin";
import { makeStyles } from '@material-ui/core/styles';
import classes from "*.module.sass";
import PriceCalculator from "./PriceCalculator";
import { Grid } from '@material-ui/core';
import CurrencySelector from "../components/CurrencySelector";
import { CountriesSelector } from "../components/CountriesSelector";

const useStyles = makeStyles(theme => ({

    itemPriceSize: {
        minWidth: "344px",
        maxWidth: "600px",
        minHeight: "246px",
    },
    itemCalculatorSize: {
        minWidth: "240px",
        maxWidth: "260px",
    },
    form: {
        display: "flex",
        '& > *': {
            margin: theme.spacing(0, 1)
        }
    },
    line: {
        border: "none"
    },
    formControl: {
        width: "100px",
        minWidth: "100px"
    },
    countrySelector: {

        padding: 0,        
    }
}));

const PricingInput = (props: any) => {
    const classes = useStyles();
    return (
        <Grid container spacing={10} wrap='wrap' direction='row'>
            <Grid item xs={8} className={classes.itemPriceSize}>
                <ArrayInput {...props}>
                    <SimpleFormIterator classes={{ form: classes.form, line: classes.line }} >

                        {/* <ReferenceArrayInput label="Countries" reference="country" source="countries" perPage={300}>
                            <AutocompleteArrayInput optionText={"id"} />

                        </ReferenceArrayInput> */}
                        <CountriesSelector
                            label="Countries" source="countries" classes={{ inputInput: classes.countrySelector }} />
                        {/* <SelectInput source="currency" label="currency" required
                            className={classes.formControl}
                            // helperText=""
                            choices={[
                                { id: 'USD', name: 'USD' },
                                { id: 'EUR', name: 'EUR' },
                                { id: 'GBP', name: 'GBP' },
                                { id: 'BUSD', name: 'BUSD' },
                                { id: 'USDT', name: 'USDT' }
                            ]}

                        /> */}
                        <CurrencySelector type="product" source="currency" label="currency" required className={classes.formControl} />
                        <NumberInput className={classes.formControl} source="price" step={1} label="price" required
                        // helperText=""
                        />
                        <NumberInput className={classes.formControl} source="priceOld" step={1} label="Price Old" required
                        // helperText=""
                        />
                    </SimpleFormIterator >
                </ArrayInput>
            </Grid>
            <Grid item xs={4} className={classes.itemCalculatorSize}>
                <PriceCalculator />
            </Grid>
        </Grid>
    )
}

export default PricingInput;