import { Box, MenuItem, Select, TextField } from "@material-ui/core"
import { useCallback } from "react"
import { FieldTitle, InputHelperText, InputProps, regex, useInput } from "react-admin"

const durationRE = /(-?(?:\d+\.?\d*|\d*\.?\d+)(?:e[-+]?\d+)?)\s*([\p{L}]*)/ui

const units = [
    { id: "s", name: "sec" },
    { id: "sec", name: "sec" },
    { id: "m", name: "min" },
    { id: "min", name: "min" },
    { id: "h", name: "hours" },
    { id: "hr", name: "hours" },
    { id: "d", name: "day" },
    { id: "w", name: "week" },
    { id: "months", name: "months" },
    { id: "y", name: "years" },
]
const validateValue = regex(/\d+[a-z]+/iu, "Invalid Duration")

export const DurationInput = (props: InputProps) => {
    const { variant = "filled", label, source, resource, validate } = props;


    const {
        input: { name, value, onChange, ...rest },
        isRequired,
        meta: {
            error,
            touched,
            submitError,

        }
    } = useInput({ ...props, validate: validateValue })

    const strVal: string = value;
    console.log({ strVal })
    const match = strVal.match(durationRE)
    const [, num, unit] = match || [, "", ""]


    const handleNumChange = useCallback((event) => {
        const val = event.target.value;
        onChange(`${val}${unit}`)
    }, [unit])

    const handleUnitChange = useCallback((event) => {
        const val = event.target.value;
        onChange(`${num}${val}`)
    }, [num])

    const textLabel = label !== '' && label !== false && (
        <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}

        />
    )

    const helperText =
        <InputHelperText
            touched={touched}
            error={error || submitError}
        // helperText={helperText}
        />


    return (
        <Box>
            <TextField variant={variant} label={textLabel} value={num} onChange={handleNumChange} helperText={helperText} />
            <Select variant={variant} label="units" value={unit} onChange={handleUnitChange}>
                {units.map(({ id, name }) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
            </Select>

        </Box>
    )
}