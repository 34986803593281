type TMediaObject = {
    id: string,
    mimetype?: string
};

const videoRootUrl = "https://videos.mypapik.com";

const fileExt: Record<string, string> = {
    "video/mp4": "mp4",
    "video/webm": "webm"
};

export default (mediaObject: TMediaObject, mimetype?: string): string => {

    const fileId = mediaObject.id
    const mime = mimetype || mediaObject.mimetype;
    if (!mime) {
        throw new Error("unknown mimetype");
    }
    const ext = fileExt[mime] || "mp4"
    const url = `${videoRootUrl}/${fileId}.${ext}`;
    return url;
};